import { useEffect, useState } from "react";
import api from "../../services/api";
import { Button } from "../../components/buttons/button.default";
import { useAcceptInstructions } from "../../hooks/useAcceptInstructions";
import '../termsOfUse/termsOfUse.css';

export default function AcceptInstructions() {
    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const { acceptInstruction } = useAcceptInstructions()

    const [instructionId, setInstructionId] = useState(undefined)
    const [fileBase64, setFileBase64] = useState(undefined)

    useEffect(() => {        
        getData()
    }, [])

    async function getData() {
        try {
            setLoading(true)
            const currentInstruction = (await api.get('/api/v1/acceptInstructions?active=1', {
                headers: {
                    authorization: token
                }
            })).data?.data[0]

            const currentFile = await api.get(`/api/v1/acceptInstructions/getFile/${currentInstruction?.id}?isBase64=true`, {
                headers: {
                    authorization: token
                }
            })

            setInstructionId(currentInstruction?.id)
            setFileBase64(currentFile?.data?.base64)
        } catch (error) {
            console.log(error)
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="w-full h-screen flex flex-col items-center justify-center gap-2">
            <h2 className="text-primaryDefaultLight text-lg font-semibold">Aceite de instruções da folha</h2>
            {
                <div className="w-[55vw] h-[80%] bg-white shadow-xl rounded-md p-4">
                    {
                        loading ?
                        <h2 className="w-full h-full text-xl text-primaryDefaultLight animate-pulse flex flex-col items-center justify-center">
                            Carregando instruções...
                        </h2> : (
                            error ?
                            <div className="w-full h-full flex flex-col items-center justify-center gap-2">   
                                <h2 className="text-zinc-900 text-base font-semibold">Ocorreu um erro ao carregar as instruções</h2>
                                <Button approval onClick={() => getData()}>Recarregar</Button>
                            </div> :
                            <iframe 
                                src={`data:application/pdf;base64,${fileBase64}#toolbar=0&navpanes=0&scrollbar=0`} 
                                height="100%" 
                                width="100%"
                            >
                            </iframe>
                        )
                    }
                </div>
            }
            {
                !error &&
                <div className="w-[55vw] flex flex-row items-end justify-end">
                    <Button onClick={() => acceptInstruction(instructionId)}>Aceitar</Button>
                </div>
            }
        </div>
    )
}
