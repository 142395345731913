import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { useLoading } from './useLoad'
import api from '../services/api'

const AcceptInstructionsContext = createContext({})

export function AcceptInstructionsProvider({ children }){
    const { logged, user } = useAuth()
    const { setLoading } = useLoading()
    const [ acceptedInstruction, setAcceptedInstruction ] = useState(true)
    const [ verify, setVerify ] = useState(false)
    const token = localStorage.getItem('@auth:token')

    useEffect(() => {
        setLoading(false)

        async function getData() {
            if (logged == true && token) {
                try {
                    const currentInstruction = (await api.get('/api/v1/acceptInstructions?active=1', {
                        headers: {
                            authorization: token
                        }
                    })).data?.data[0]

                    if (!!currentInstruction && user?.type == 3) {
                        const verifyUserAcceptedInstruction = (await api.get(`/api/v1/acceptInstructionsUsers/${currentInstruction?.id}`, {
                            headers: {
                                authorization: token
                            }
                        })).data
    
                        if(verifyUserAcceptedInstruction.data.accepted == true){
                            setLoading(false)
                            return setAcceptedInstruction(true)
                        }
                        return setAcceptedInstruction(false)    
                    }
                } catch (error) {
                    return alert(error?.response?.data?.message)
                } finally {
                    setLoading(false)
                }
            }
        }

        setVerify(false)    
        getData()
    }, [logged, verify])

    async function acceptInstruction(acceptInstructions_id) {
        try {
            setLoading(true)
            await api.post('/api/v1/acceptInstructionsUsers', {
                acceptInstructions_id,
            }, {
                headers: {
                    authorization: token
                }
            })

            setVerify(true)
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    return (
        <AcceptInstructionsContext.Provider value={{ acceptedInstruction, acceptInstruction }}>
            { children }
        </AcceptInstructionsContext.Provider>
    )
}

export function useAcceptInstructions() {
    return useContext(AcceptInstructionsContext)
}