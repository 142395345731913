import React from 'react';
import './global.css';

import { AuthProvider } from './hooks/useAuth';
import { TermOfUseProvider } from './hooks/useTermsOfUse';
import { LoadingProvider } from './hooks/useLoad'
import { AcceptInstructionsProvider } from './hooks/useAcceptInstructions';
import Routes from './routes/redirect.routes';

function App() {
    return (
        <LoadingProvider>
            <AuthProvider>
                <TermOfUseProvider>
                    <AcceptInstructionsProvider>
                        <Routes />
                    </AcceptInstructionsProvider>
                </TermOfUseProvider>
            </AuthProvider>
        </LoadingProvider>
    );
}

export default App;
