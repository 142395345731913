import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FiSearch, FiEdit3, FiCheckSquare, FiSquare, FiUnlock, FiLock, FiX } from 'react-icons/fi'
import { useMenu } from '../../hooks/useMenu'
import api from '../../services/api'
import { useCompanyBranch } from '../../hooks/useCompanyBranch'
import { useAuth } from '../../hooks/useAuth'
import Loader from '../../components/Loader/loader'
import { useModal } from '../../hooks/useModal'
import Modal from '../../components/Modal/modal'
import { Button } from '../../components/buttons/button.default'
import moment from 'moment'

export default function PlanoSaudePeriodos() {
    const navigate = useNavigate()
    const { company, branch } = useCompanyBranch()
    const { user } = useAuth()
    const { setMenuTitle } = useMenu()
    setMenuTitle('Histórico de Plano de saúde')
    const auth = localStorage.getItem('@auth:token')
    const { show, setShow } = useModal()
    const { setShowNotificationModal, setNotificationModalText } = useModal()
    const [page, setPage] = useState(1)
    const [periods, setperiods] = useState([])
    const [periodsShow, setPeriodsShow] = useState([])
    const [totalPeriods, setTotalPeriods] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingApi, setLoadingApi] = useState(false)
    const [periodId, setPeriodId] = useState(undefined)
    const [periodDescription, setPeriodDescription] = useState('')
    const [buttonClosePeriod, setButtonClosePeriod] = useState(false)
    const [buttonOpenPeriod, setButtonOpenPeriod] = useState(false)

    async function getPeriods() {
        if (company === 0 || branch === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }

        setShow(false)
        setLoadingApi(false)
        try {
            const response = await api.get(`/api/v1/periodsHealthPlan/${company}/${branch}`, {
                headers: {
                    Authorization: auth
                }
            })

            let totalItemsGroup = response.data.data.length / 10
            if (totalItemsGroup % 1 !== 0) {
                totalItemsGroup = parseInt(totalItemsGroup) >= totalItemsGroup ? totalItemsGroup : parseInt(totalItemsGroup) + 1
            }
            setTotalPeriods(totalItemsGroup)
            setperiods(response.data.data)
            setLoading(false)
        } catch (error) {
            return alert('Erro ao realizar busca de peridos.')
        }
    }

    useEffect(() => {
        setLoading(true)
        getPeriods()
    }, [company, branch, auth, loadingApi])

    useEffect(() => {
        setLoading(true)
        if (periods.length > 0) {
            const finish = page * 10 - 1
            const initial = finish - 9

            const selectedPeriods = periods.map((period, index) => {
                if (index >= initial && index <= finish) {
                    return period
                }
            }).filter(period => period)

            setPeriodsShow(selectedPeriods)
        }
        setLoading(false)

    }, [periods, page])

    useEffect(() => {
        if (!show) {
            setPeriodId(undefined)
            setPeriodDescription('')
            setButtonClosePeriod(false)
            setButtonOpenPeriod(false)
        }
    }, [show])

    async function handleOpenPeriod(period) {
        setLoadingApi(true)
        try {
            await api.put(`api/v1/periodsHealthPlan/open/company/${company}/branch/${branch}`, {
                period: period
            }, {
                headers: {
                    Authorization: auth
                }
            })
            await getPeriods()

            setShow(false)
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Período aberto com sucesso')
        } catch(error) {
            setShow(false)
            setLoading(false)
            return alert('Erro ao reabrir período. Se o problema persistir, entre em contato com o suporte.')
        }
    }

    return (
        <div className='px-1 lg:px-10'>
            {
                loading ?
                    (
                        <Loader />
                    )
                    :
                    (
                        <>
                            {
                                show && buttonOpenPeriod ?
                                <Modal>
                                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                                        <div className='flex justify-between pl-3 py-5 items-center'>
                                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                                <p className='mr-7'>{'Deseja reabrir o período ' + periodDescription + '?'}</p>
                                            </p>
                                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={() => setShow(false)} title={'Fechar'} />
                                        </div>
                                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                            <Button background={'bg-red-800'} onClick={() => setShow(false)}>Cancelar</Button>
                                            <Button background={'bg-green-800'} type={'button'} onClick={() => handleOpenPeriod(periodId)}>Confirmar</Button>
                                        </div>
                                    </div>
                                </Modal>
                                :
                                <></>
                            }
                            <div className='animate-[wiggleLeft_1s_ease-in-out] w-full flex flex-row flex-wrap mt-16 max-h-[65vh] lg:max-h-[75vh] lg:h-[25rem] items-center justify-center gap-8 px-2 py-10  overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                                {
                                    periodsShow.map(period => {
                                        let iconStatus
                                        let realeasePeriod
                                        let textStatus
                                        let option
                                        if ((period.external_situation == 'T' || period.external_situation == 'P') || (period?.isClosed)) {
                                            realeasePeriod = <FiLock 
                                                className='hidden lg:flex cursor-pointer text-2xl text-[#757575]' 
                                                onClick={() => user.type < 3 ? `{${setPeriodId(period?.period)}; ${setShow(true)}; ${setButtonOpenPeriod(true)}; ${setPeriodDescription(moment(period?.period_description)?.utc(false)?.format("MM/YYYY"))}}` : {}}
                                            />
                                            textStatus = <p className='text-primaryDefaultLight'>Concluído</p>
                                            iconStatus = <FiCheckSquare className="hidden lg:flex cursor-pointer text-2xl text-primaryDefaultLight" />
                                            option = <Link className='hidden lg:flex' to={`/healthPlan/consult/${period?.period}`}><FiSearch className="cursor-pointer text-2xl text-gray-700" /></Link>
                                        } else if (period.external_situation == 'I' || (!period?.isClosed && (period.external_situation != 'T' && period.external_situation != 'P'))) {
                                            realeasePeriod = <FiUnlock 
                                                className='hidden lg:flex text-2xl text-yellow-500 cursor-not-allowed'
                                            />
                                            textStatus = <p className='text-primaryDefaultLight'>Pendente</p>
                                            iconStatus = <FiSquare className="hidden lg:flex text-2xl text-primaryDefaultLight" />
                                            option = <Link to={`/healthPlan/${period?.period}`}><FiEdit3 className='hidden lg:flex cursor-pointer text-2xl text-gray-700' /></Link>
                                        }
                                        if (user.type !== 3) {
                                            return (
                                                <div className="lg:translate-x-6 border border-gray-100 rounded-md w-48 h-36 p-2 flex flex-col duration-300 shadow-[0_0px_16px_0px_rgb(0_0_0_/_0.1)] shadow-gray-400 hover:scale-110 hover:shadow-3xl" key={period.period}>
                                                    <div className='flex flex-row justify-between'>
                                                        {realeasePeriod}
                                                        <div className='flex flex-row gap-1'>
                                                            {textStatus}
                                                            {iconStatus}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-6 items-center" >
                                                        <p className='pb-4 text-xl font-normal lg:text-3xl lg:font-black text-[#757575]'>{moment(period.period_description).utc(false).format('MM/YYYY')}</p>
                                                        {option}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div className="border border-gray-100 rounded-md w-48 h-36 p-2 flex flex-col duration-300 shadow-md shadow-gray-400 hover:scale-110 hover:shadow-3xl" key={period.period}>
                                                <div className='flex flex-row justify-between'>
                                                    {realeasePeriod}
                                                    <div className='flex flex-row gap-1'>
                                                        {textStatus}
                                                        {iconStatus}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-6 items-center" >
                                                    <p className='pb-4 text-3xl font-black text-[#757575]'>{period.period}</p>
                                                    {option}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='flex gap-4 justify-center mt-1 p-2 lg:mt-8 items-center'>
                                {
                                    Array.apply(null, Array(totalPeriods)).map((_, index) => {
                                        const button = <button
                                            className={`border border-gray-400 p-1 w-7 lg:p-3 rounded-full lg:w-11 hover:bg-gray-200 ${index + 1 == page ? 'bg-gray-300' : 'bg-white'}`}
                                            onClick={() => setPage(index + 1)}
                                        >{index + 1}</button>
                                        let existsPoints = false
                                        let showPointers = false

                                        if (totalPeriods < 5) {
                                            return button
                                        } else {
                                            existsPoints = true
                                            if (index == 0 || index + 1 == totalPeriods) {
                                                return button
                                            } else if (page + 2 > index + 1 && page - 2 < index + 1) {
                                                return button
                                            } else {
                                                if (page + 2 < index + 1 || page - 2 > index + 1) {
                                                    showPointers = true
                                                }
                                                if (existsPoints && showPointers == false) {
                                                    return <a className='flex items-center'>...</a>
                                                }
                                            }
                                        }
                                    })
                                }
                            </div>
                        </>
                    )
            }
        </div>
    )
}
