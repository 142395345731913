import { useEffect, useState } from "react"
import { LabelInput } from "../../components/label/label.input"
import { InputAutoComplete } from "../../components/input/input.autocomplete"
import { Button } from '../../components/buttons/button.default'
import { useMenu } from "../../hooks/useMenu"
import { Input } from "../../components/input/input"
import Multiselect from "../../components/selects/mult.select"
import { FaCheck, FaDownload, FaExchangeAlt, FaMinus, FaPlus } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import { useCompanyBranch } from "../../hooks/useCompanyBranch"
import { useAuth } from "../../hooks/useAuth"
import api from "../../services/api"
import Loader from "../../components/Loader/loader"
import { TableDefault } from '../../components/table/table.default'
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { useModal } from '../../hooks/useModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import Modal from "../../components/Modal/modal"

export default function PlanoSaude({ isConsult }) {
    const { user } = useAuth()
    const { company, branch } = useCompanyBranch()
    const { id: periodId } = useParams()
    const token = localStorage.getItem('@auth:token')
    const { setMenuTitle } = useMenu()
    setMenuTitle('Plano de saúde')
    const navigate = useNavigate()
    const { show, setShow, setShowNotificationModal, setNotificationModalText } = useModal()
    const [detectModal, setDetectModal] = useState("")
    const [loading, setLoading] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [showTable, setShowTable] = useState(false)

    const boolOptions = [
        { value: true, label: "Incluir" },
        { value: false, label: "Remover" },
    ]
    const [periodData, setPeriodData] = useState([])
    const [persons, setPersons] = useState([])
    const [dependents, setDependents] = useState([])
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [observation, setObservation] = useState('')
    const [selectedDependent, setSelectedDependent] = useState({})
    const [addPersons, setAddPersons] = useState([
        { id: Date.now(), name: undefined, numcad: undefined, observation: undefined, action: undefined }
    ])
    const [addDependents, setAddDependents] = useState([])
    const [branches, setBranches] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])
    const [changeFile, setChangeFile] = useState(false)
    const [changeBranches, setChangeBranches] = useState(false)
    const tableThead = [
        {
            "name": "Código",
            "original_name": "numcad"
        },
        {
            "name": "Colaborador",
            "original_name": "name"
        },
        {
            "name": "Ação",
            "original_name": "action"
        },
        {
            "name": "Dependentes",
            "original_name": "dependents"
        }
    ]

    useEffect(() => {
        async function getData() {
            try {
                const responsePersons = await api.get(`api/v1/persons/persons/${company}/${branch}/${periodId}`, {
                    headers: {
                        Authorization: token,
                    }
                })
                const responseDependents = await api.get(`/api/v1/sendHealthPlan/getDependents`, {
                    headers: {
                        Authorization: token,
                    }
                })

                const foundPeriod = await api.get(`/api/v1/periodsHealthPlan/${company}/${branch}/${periodId}`, {
                    headers: {
                        Authorization: token,
                    }
                })
                if (!!foundPeriod?.data?.data[0]?.id) {
                    setIsEdit(true)

                    const personsExistsHealthPlan = await api.get(`/api/v1/sendHealthPlan/getPersons/${foundPeriod?.data?.data[0]?.id}`, {
                        headers: {
                            Authorization: token,
                        }
                    })
                    if (personsExistsHealthPlan?.data?.data?.length > 0) {
                        setShowTable(true)
                        setAddPersons(_ => personsExistsHealthPlan?.data?.data?.map(items => {
                            const dependentsInPerson = JSON.parse(items?.dependents)

                            return {
                                ...items,
                                numcad: items?.person,
                                name: responsePersons?.data?.find(pers => pers?.numcad == items?.person)?.nomfun,
                                addedDependents: responseDependents.data?.data?.filter(deps => (
                                    deps?.numcad == items?.person &&
                                    dependentsInPerson?.some(item => item?.coddep == deps?.coddep)
                                )),
                                dependents: <div className="w-full flex flex-row items-center justify-center gap-4">
                                    <span
                                        onClick={() => {
                                            setSelectedDependent({
                                                holder_name: responsePersons?.data?.find(pers => pers?.numcad == items?.person)?.nomfun,
                                                holder_numcad: items?.person,
                                                data: responseDependents.data?.data?.filter(deps => deps?.numcad == items?.person),
                                            });
                                            setShow(true);
                                            setDetectModal("addDependents");
                                        }}
                                        className="hover:cursor-pointer hover:opacity-80 text-gray-800 underline"
                                    >
                                        Ver dependentes
                                    </span>
                                    <div className="text-primaryDefaultLight text-xs font-semibold flex flex-row items-center gap-[4px]">
                                        <p>{dependentsInPerson?.length || 0}</p>
                                        <h2>Adicionados</h2>
                                    </div>
                                </div>
                            }
                        }))
                    }
                }

                if (user?.companyOrigin == 'senior' || !user?.companyOrigin) {
                    const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                        headers: {
                            authorization: user.token
                        }
                    })

                    const formattedListBranches = foundPeriod?.data?.data[0]?.branches
                        ?.trim()
                        ?.split("|")
                        ?.map(items => Number(items) > 0 && Number(items))
                        ?.filter(Boolean)

                    if (!!foundPeriod?.data?.data[0]?.id) {
                        const existsBranches = resultBranches?.data?.data?.filter(branchs => {
                            if (formattedListBranches?.includes(branchs?.value) && branchs?.value != branch) {
                                return {
                                    ...branchs
                                }
                            }
                        })
                        setBranches(existsBranches)
                    } else {
                        setBranches(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                    }
                } else {
                    const resultBranches = await api.get(`api/v1/companies/branchQuestor/${company}`, {
                        headers: {
                            authorization: user.token
                        }
                    })
                    setBranches(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                }

                setPeriodData(foundPeriod?.data?.data[0])
                setPersons(responsePersons.data)
                setDependents(responseDependents.data?.data)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getData()
    }, [])

    async function handleSubmit() {
        setLoading(true)
        const formData = new FormData()
        const dependentsList = []

        try {
            if (!selectedFile && !periodData?.filename) {
                return alert("Informe um arquivo para o lançamento.")
            }
            if (selectedBranchs?.length === 0 && !periodData?.branches) {
                return alert("Selecione no mínimo uma filial para prosseguir.")
            }
            for (const persons of addPersons?.filter(persons => !!persons?.numcad)) {
                delete persons?.dependents

                if (isEdit && !!persons?.addedDependents) {
                    dependentsList.push(...persons?.addedDependents)
                }
                delete persons?.addedDependents
            }
            
            formData.append('file', selectedFile)
            formData.append('data', JSON.stringify({
                period: periodId,
                filename: selectedFile?.name || periodData?.filename,
                originalFilename: selectedFile?.name || periodData?.originalFilename,
                observation: observation || periodData?.observation || null,
                branches: `${selectedBranchs?.length > 0 ?
                    `|${selectedBranchs.map(branchs => String(branchs?.value)?.trim()).join('|')}|${branch}|` :
                    !!periodData?.branches ?
                        periodData?.branches :
                        `|${branch}|`
                    }`,
                personsActions: addPersons?.filter(persons => !!persons?.numcad),
                dependents: isEdit ?
                    (dependentsList?.length > 0 ? dependentsList : []) :
                    addDependents
            }))
            await api.post('/api/v1/sendHealthPlan', formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data',
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Lançamento salvo com sucesso')
            return navigate(-1)
        } catch (error) {
            console.log(error)
            alert("Ocorreu um erro ao salvar lançamento")
        } finally {
            setLoading(false)
        }
    }

    async function handleDownloadFile() {
        setLoading(true)
        try {
            const file = await api.get(`/api/v1/sendHealthPlan/getFile/${periodData?.id}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })

            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `Lancamento_Plano_Saude.${periodData?.filename?.split('.')[1]}`)
            myElement.click()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }
    }

    async function handleAddPersons() {
        const controlledFields = []

        if (detectModal === "addPersons") {
            addPersons.forEach(person => {
                const numcadElement = document.getElementById(`numcad-${person?.id}`)
                const actionElement = document.getElementById(`action-${person?.id}`)
                const foundPerson = numcadElement?.value
                const foundPersonAction = actionElement?.value

                if (!!foundPerson && !foundPersonAction) {
                    actionElement.style.border = '1px solid red'
                    controlledFields.push({ id: person?.id })
                } else if (!!foundPersonAction && !foundPerson) {
                    numcadElement.style.border = '1px solid red'
                    controlledFields.push({ id: person?.id })
                } else if (!foundPerson && !foundPersonAction) {
                    numcadElement.style.border = '1px solid red'
                    actionElement.style.border = '1px solid red'
                    controlledFields.push({ id: person?.id })
                } else {
                    if (numcadElement) {
                        numcadElement.style.border = '1px solid #CED3DA'
                        controlledFields.filter(fields => fields?.id != person?.id)
                    }
                    if (actionElement) {
                        actionElement.style.border = '1px solid #CED3DA'
                        controlledFields.filter(fields => fields?.id != person?.id)
                    }
                }
            })
        }

        if (controlledFields?.length === 0) {
            setShow(false)
            setDetectModal('')
            setShowTable(true)

            setAddPersons(prev => prev.map(items => {
                const totalAddedDependents = addPersons?.find(pers => pers?.numcad == items?.numcad)?.addedDependents?.length

                return {
                    ...items,
                    observation: !!items?.observation ? items?.observation : undefined,
                    dependents: <div className="w-full flex flex-row items-center justify-center gap-4">
                        <span
                            onClick={() => {
                                setSelectedDependent({
                                    holder_name: items?.name,
                                    holder_numcad: items?.numcad,
                                    data: dependents?.filter(deps => deps?.numcad == items?.numcad)
                                });
                                setShow(true);
                                setDetectModal("addDependents");
                            }}
                            className="hover:cursor-pointer hover:opacity-80 text-gray-800 underline"
                        >
                            Ver dependentes
                        </span>
                        <div className="text-primaryDefaultLight text-xs font-semibold flex flex-row items-center gap-[4px]">
                            {
                                !isEdit ?
                                    <p>{addDependents?.filter(deps => deps?.holder_numcad == items?.numcad)?.length || 0}</p> :
                                    <p>{totalAddedDependents || 0}</p>
                            }
                            <h2>Adicionados</h2>
                        </div>
                    </div>
                }
            }))
        }
    }

    function renderAddPersonsAndDependents() {
        return <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-primaryDefaultLight text-lg font-semibold">Colaboradores do plano</p>
            {
                !isConsult &&
                <span
                    onClick={() => {
                        setShow(true);
                        setDetectModal("addPersons");
                    }}
                    className="hover:cursor-pointer hover:opacity-80 text-gray-800 underline"
                >
                    Clique para adicionar ou remover colaboradores deste plano
                </span>
            }
            {
                showTable ?
                <div className="w-full h-full mt-2 mb-8">
                    <TableDefault
                        title={tableThead}
                        data={addPersons}
                        quantity={addPersons?.length}
                        collumns={["numcad", "name", "action", "dependents"]}
                        hasMarginBottom={false}
                    />
                </div> :
                    isConsult &&
                    <p className="text-zinc-900 text-base">Nada a exibir</p>
            }
        </div>
    }

    return (
        <>
            {
                show && detectModal === "addPersons" ?
                    <Modal>
                        <div className="relative w-full flex flex-col items-start gap-6 p-6">
                            <div className="w-full flex flex-row items-center justify-between">
                                <h2 className="text-primaryDefaultLight text-lg font-semibold">Colaboradores do plano</h2>
                                <span
                                    onClick={() => {
                                        setShow(false);
                                        setDetectModal('');
                                    }}
                                    className="bg-gray-100 rounded-full p-2 cursor-pointer"
                                >
                                    <MdClose size={16} />
                                </span>
                            </div>
                            <div className="overflow-auto max-h-[22rem] min-h-[22rem] w-full xl:w-[62rem] h-full flex flex-col items-start gap-8 xl:gap-4">
                                {
                                    addPersons.map((items, i) => {
                                        return <>
                                            <div className="w-full flex flex-col items-start justify-start gap-2">
                                                <div className={`
                                                    flex flex-col items-start justify-start xl:flex xl:flex-row xl:items-center xl:justify-center gap-2
                                                    ${(addPersons?.length - 1) == i && 'mb-12'}
                                                `}>
                                                    <div className="flex flex-col items-start">
                                                        <LabelInput>Selecione um colaborador: *</LabelInput>
                                                        <LabelInput>
                                                            <InputAutoComplete
                                                                id={`numcad-${items?.id}`}
                                                                data={persons}
                                                                selectedLabel={'nomfun'}
                                                                optionList={['numcad', 'nomfun']}
                                                                preSelectedValue={items?.name || ''}
                                                                value={items?.value || ''}
                                                                onChange={(e) => {
                                                                    setAddPersons(persons => persons.map(item => {
                                                                        if (item?.id === items?.id) {
                                                                            return {
                                                                                ...item,
                                                                                name: e?.nomfun,
                                                                                numcad: e?.numcad
                                                                            }
                                                                        }
                                                                        return item
                                                                    }))
                                                                }}
                                                                placeHolder='Selecionar'
                                                                width={72}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    <div className="flex flex-col items-start">
                                                        <LabelInput>Informe uma observação: </LabelInput>
                                                        <LabelInput>
                                                            <Input
                                                                placeholder={"Observação"}
                                                                onChange={(e) => {
                                                                    setAddPersons(persons => persons.map(item => {
                                                                        if (item?.id === items?.id) {
                                                                            return {
                                                                                ...item,
                                                                                observation: e.target.value
                                                                            }
                                                                        }
                                                                        return item
                                                                    }))
                                                                }}
                                                                value={
                                                                    (!items?.observation || items?.observation == "null") ?
                                                                        '' :
                                                                        items?.observation
                                                                }
                                                                width={72}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    <div className="flex flex-col items-start">
                                                        <LabelInput>Informe a ação desejada: *</LabelInput>
                                                        <LabelInput>
                                                            <InputAutoComplete
                                                                id={`action-${items?.id}`}
                                                                data={boolOptions}
                                                                selectedLabel={'label'}
                                                                optionList={['label']}
                                                                preSelectedValue={
                                                                    boolOptions.find(bool => bool?.value == items?.addPerson)?.label ||
                                                                    (items?.action == "incluir" ? "Incluir" : items?.action == "remover" ? "Remover" : '')
                                                                }
                                                                value={
                                                                    boolOptions.find(bool => bool?.value == items?.addPerson)?.label ||
                                                                    (items?.action == "incluir" ? "Incluir" : items?.action == "remover" ? "Remover" : '')
                                                                }
                                                                onChange={(e) => {
                                                                    setAddPersons(persons => persons.map(item => {
                                                                        if (item?.id === items?.id) {
                                                                            return {
                                                                                ...item,
                                                                                action: e?.value ? 'incluir' : 'remover'
                                                                            }
                                                                        }
                                                                        return item
                                                                    }))
                                                                }}
                                                                placeHolder='Selecione a ação'
                                                                width={72}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    <div className="flex flex-row items-center gap-2 mt-6">
                                                        <FaPlus
                                                            onClick={() => setAddPersons((prev) => [...prev, {
                                                                id: Date.now(),
                                                                name: '',
                                                                observation: ''
                                                            }])}
                                                            className="cursor-pointer text-base text-primaryDefaultLight font-bold"
                                                        />
                                                        <FaMinus
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (addPersons.length > 1) {
                                                                    if (!isEdit) {
                                                                        setAddPersons((prev) => prev.filter(item => item?.id !== items?.id))
                                                                    } else {
                                                                        setAddPersons((prev) => prev.filter(item => item?.numcad !== items?.numcad))
                                                                    }
                                                                }
                                                            }}
                                                            className={`${addPersons?.length > 1 ? 'cursor-pointer' : 'cursor-not-allowed'} text-primaryDefaultLight text-base font-bold`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="absolute bottom-0 right-0 z-20 w-full h-12 bg-white flex flex-row items-center justify-center gap-2">
                                                <div className="w-full flex flex-row items-end justify-end gap-6 px-6">
                                                    <Button
                                                        approval={true}
                                                        onClick={() => handleAddPersons()}>
                                                        Salvar
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </Modal> :
                    <></>
            }
            {
                show && detectModal === "addDependents" ?
                    <Modal>
                        <div className="relative w-full flex flex-col items-start gap-6 p-6">
                            {
                                isConsult ?
                                    <div className="w-full flex flex-row items-center justify-between">
                                        <h2 className="text-primaryDefaultLight text-lg font-semibold">Dependentes de {selectedDependent?.holder_name || '-'}</h2>
                                        <span onClick={() => { setShow(false); setDetectModal(''); }} className="cursor-pointer bg-gray-300/50 rounded-full p-2"><MdClose size={16} /></span>
                                    </div> :
                                    <h2 className="text-primaryDefaultLight text-lg font-semibold">Dependentes de {selectedDependent?.holder_name || '-'}</h2>
                            }
                            <div className="overflow-auto max-h-[22rem] min-h-[22rem] w-full xl:w-[32rem] h-full flex flex-col items-start gap-8 xl:gap-4 pb-6">
                                {
                                    selectedDependent?.data?.map((items) => {
                                        const selectedPerson = addPersons?.find(pers => pers?.numcad == items?.numcad)
                                        const addedDependent = !isEdit ?
                                            !!addDependents?.find(deps => (
                                                deps?.dep_coddep == items?.coddep &&
                                                deps?.dep_numcad == items?.numcad
                                            ))?.holder_numcad :
                                            !!selectedPerson?.addedDependents?.find(pers => (
                                                pers?.coddep == items?.coddep
                                            ))

                                        return <>
                                            <div className="w-full flex flex-row items-center justify-between border-b border-gray-300 pb-4">
                                                <div className="w-full flex flex-col items-start gap-[4px]">
                                                    <h2 className="w-1/2 text-base flex flex-row">Nome: {items?.nomdep || '-'}</h2>
                                                    {
                                                        addedDependent ?
                                                            <p className="flex flex-row items-center gap-2 text-sm font-semibold">
                                                                <FaCheck className="text-green-500" /> Adicionado
                                                            </p> :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    !isConsult &&
                                                    (
                                                        addedDependent ?
                                                            <Button
                                                                onClick={() => {
                                                                    if (!isEdit) {
                                                                        setAddDependents(prev => prev.filter(deps => (
                                                                            deps?.dep_coddep != items?.coddep
                                                                        )))
                                                                    } else {
                                                                        const selectedPerson = addPersons?.find(pers => pers?.numcad == items?.numcad)
                                                                        const newDependentsList = selectedPerson?.addedDependents?.filter(deps => deps?.coddep != items?.coddep)

                                                                        setAddPersons(prev =>
                                                                            prev.map(pers => {
                                                                                if (pers?.numcad == items?.numcad) {
                                                                                    return {
                                                                                        ...pers,
                                                                                        addedDependents: newDependentsList,
                                                                                    }
                                                                                }
                                                                                return pers
                                                                            })
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                Remover
                                                            </Button> :
                                                            <Button
                                                                onClick={() => {
                                                                    if (!isEdit) {
                                                                        setAddDependents(prev => [...prev, {
                                                                            holder_numcad: selectedDependent?.holder_numcad,
                                                                            dep_numcad: items?.numcad,
                                                                            dep_coddep: items?.coddep,
                                                                        }])
                                                                    } else {
                                                                        setAddPersons(prev =>
                                                                            prev.map(pers => {
                                                                                if (pers?.numcad == items?.numcad) {
                                                                                    return {
                                                                                        ...pers,
                                                                                        addedDependents: [...(pers.addedDependents || []), { numcad: items?.numcad, coddep: items?.coddep }],
                                                                                    }
                                                                                }
                                                                                return pers
                                                                            })
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                Adicionar
                                                            </Button>
                                                    )
                                                }
                                            </div>
                                            <div className="absolute bottom-0 right-0 z-20 w-full h-12 bg-white flex flex-row items-center justify-center gap-2">
                                                <div className="w-full flex flex-row items-end justify-end px-6">
                                                    {
                                                        !isConsult &&
                                                        <Button
                                                            approval={true}
                                                            onClick={() => {
                                                                handleAddPersons();
                                                            }}>
                                                            Salvar
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </Modal> :
                    <></>
            }
            {
                loading ?
                    <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                        <Loader />
                    </div> :
                    <div className="w-full h-full flex flex-col items-center justify-center py-12 gap-6">
                        <div className="w-[60vw] xl:w-fit h-[85vh] shadow-xl rounded-sm p-6 flex flex-col items-center justify-start">
                            <strong className="text-primaryDefaultLight text-lg">{
                                !loading ?
                                    moment(periodData?.period_description).utc(false).format("MM/YYYY") :
                                    '--/--/----'
                            }</strong>
                            {
                                (!!periodData?.company || !isConsult) ?
                                    <div className="w-full max-h-[30rem] overflow-auto h-full border border-gray-300/50 rounded-sm mt-6 p-6 flex flex-col items-center justify-start gap-6">
                                        {
                                            !isEdit ?
                                                <div className="w-[50vw] h-full flex flex-col items-start justify-start gap-12">
                                                    <div className="w-full flex flex-col gap-2 xl:flex xl:flex-row xl:gap-0 items-start justify-between">
                                                        <div className="w-full flex flex-col items-start justify-start gap-2">
                                                            <div className='w-80 text-sm sm:text-sm flex flex-col items-start justify-center gap-[4px]'>
                                                                <LabelInput>Arquivo *</LabelInput>
                                                                <div className="w-full p-[6px] border border-gray-300 border-opacity-75 text-sm sm:text-sm rounded-lg">
                                                                    <input
                                                                        type="file"
                                                                        className="text-black"
                                                                        onChange={(e) => setSelectedFile(e?.target?.files[0])}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='w-full flex flex-col items-start'>
                                                                <label>Filiais *</label>
                                                                {
                                                                    !loading ?
                                                                        <LabelInput>
                                                                            <Multiselect
                                                                                items={[{ label: "0 - Todas", value: 'Todas' }, ...branches]}
                                                                                mult
                                                                                height={'0px'}
                                                                                onChange={e => {
                                                                                    if (e[0]?.value == "Todas") {
                                                                                        setSelectedBranchs(branches)
                                                                                    } else {
                                                                                        setSelectedBranchs(e)
                                                                                    }
                                                                                }}
                                                                                selectedLabel={'label'}
                                                                                value={selectedBranchs ? selectedBranchs : []}
                                                                                id={`selectedBranchs`}
                                                                            />
                                                                        </LabelInput> :
                                                                        <input
                                                                            disabled
                                                                            placeholder="Buscando filiais..."
                                                                            className="w-80 h-10 bg-transparent border border-gray-300/50 rounded-md p-2"
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex flex-col items-start justify-center gap-[4px]">
                                                            <label for="observation">Observação:</label>
                                                            <textarea
                                                                id="observation"
                                                                name="observation"
                                                                className="h-[7.5rem] w-full border border-gray-300/50 rounded-sm p-2 resize-none"
                                                                onChange={(e) => setObservation(e.target.value)}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {renderAddPersonsAndDependents()}
                                                </div> :
                                                <div className="w-[50vw] h-full flex flex-col items-start justify-start gap-4">
                                                    {
                                                        changeFile ?
                                                            <div className='w-80 text-sm sm:text-sm flex flex-col items-start justify-center gap-[4px]'>
                                                                <LabelInput>Arquivo *</LabelInput>
                                                                <div className="w-full p-[6px] border border-gray-300 border-opacity-75 text-sm sm:text-sm rounded-lg">
                                                                    <input
                                                                        type="file"
                                                                        className="text-black"
                                                                        onChange={(e) => setSelectedFile(e?.target?.files[0])}
                                                                    />
                                                                </div>
                                                            </div> :
                                                            <div className="w-fit flex flex-row items-center gap-4">
                                                                <h2 className="text-primaryDefaultLight font-medium text-base">Arquivo selecionado: {periodData?.originalFilename}</h2>
                                                                <Tippy
                                                                    content={'Fazer download'}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={80}>
                                                                    <div>
                                                                        <p
                                                                            className="text-primaryDefaultLight hover:cursor-pointer"
                                                                            onClick={() => handleDownloadFile()}
                                                                        >
                                                                            <FaDownload />
                                                                        </p>
                                                                    </div>
                                                                </Tippy>
                                                                {
                                                                    !isConsult &&
                                                                    <Tippy
                                                                        content={'Trocar arquivo'}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={80}>
                                                                        <div>
                                                                            <p
                                                                                className="text-primaryDefaultLight hover:cursor-pointer"
                                                                                onClick={() => setChangeFile(true)}
                                                                            >
                                                                                <FaExchangeAlt />
                                                                            </p>
                                                                        </div>
                                                                    </Tippy>
                                                                }
                                                            </div>
                                                    }
                                                    <div className="flex flex-col items-start gap-2">
                                                        <div className="flex flex-row items-center gap-2">
                                                            <h2 className="text-primaryDefaultLight font-medium text-base">Filiais selecionadas:</h2>
                                                            {
                                                                !isConsult &&
                                                                <Tippy
                                                                    content={'Trocar filiais'}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={80}>
                                                                    <div>
                                                                        <p
                                                                            className="text-primaryDefaultLight hover:cursor-pointer"
                                                                            onClick={() => setChangeBranches(true)}
                                                                        >
                                                                            <FaExchangeAlt />
                                                                        </p>
                                                                    </div>
                                                                </Tippy>
                                                            }
                                                        </div>
                                                        {
                                                            changeBranches ?
                                                                <LabelInput>
                                                                    <Multiselect
                                                                        items={[{ label: "0 - Todas", value: 'Todas' }, ...branches]}
                                                                        mult
                                                                        height={'0px'}
                                                                        onChange={e => {
                                                                            if (e[0]?.value == "Todas") {
                                                                                setSelectedBranchs(branches)
                                                                            } else {
                                                                                setSelectedBranchs(e)
                                                                            }
                                                                        }}
                                                                        selectedLabel={'label'}
                                                                        value={selectedBranchs ? selectedBranchs : []}
                                                                        id={`selectedBranchs`}
                                                                    />
                                                                </LabelInput> :
                                                                branches.map(branchs => {
                                                                    return <p className="text-zinc-900 text-xs">{branchs?.nomfil} - {branchs?.cgccpf}</p>
                                                                })
                                                        }
                                                    </div>
                                                    <div className="w-full flex flex-col items-start gap-2">
                                                        <h2 className="text-primaryDefaultLight font-medium text-base">Observação:</h2>
                                                        <div className="w-full flex flex-col items-start justify-center gap-[4px]">
                                                            <textarea
                                                                id="observation"
                                                                name="observation"
                                                                className="h-[7.5rem] w-full border border-gray-300/50 rounded-sm p-2 resize-none placeholder:text-primaryDefaultLight"
                                                                onChange={(e) => setObservation(e.target.value)}
                                                                placeholder={
                                                                    periodData?.observation?.trim() !== "null" ?
                                                                        periodData?.observation :
                                                                        "Nada a exibir"
                                                                }
                                                                value={observation}
                                                                disabled={isConsult}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {renderAddPersonsAndDependents()}
                                                </div>
                                        }
                                    </div> :
                                    <div className="w-[60vw] mt-6 p-6">
                                        <p className="text-zinc-900 text-base">Não há nada a ser consultado no período informado.</p>
                                    </div>
                            }
                        </div>
                        <div className="w-full flex flex-row items-center justify-center pb-6 gap-6">
                            <Button approval={false} onClick={() => navigate(-1)}>Voltar</Button>
                            {
                                !isConsult &&
                                <Button approval={true} onClick={() => handleSubmit()}>Salvar</Button>
                            }
                        </div>
                    </div>
            }
        </>
    )
}
