import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import Loader from '../../components/Loader/loader';
import { LabelInput } from '../../components/label/label.input';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import CurrencyInput from '../../components/input/inputCurrencyPtBr';
import { Button } from '../../components/buttons/button.default';
import { useMenu } from '../../hooks/useMenu'
import { FaPrint } from 'react-icons/fa';
import { useModal } from '../../hooks/useModal';

export function Rescision() {

    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const { company, branch } = useCompanyBranch()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText, setShowAprovalModal } = useModal()
    const { setMenuTitle } = useMenu()
    setMenuTitle('Prévia')
    const [employeeList, setEmployeeList] = useState([])
    const [reasonRescision, setReasonRescision] = useState([])
    const typeEarlyWarning = [
        {
            id: 1,
            description: 'Trabalhado'
        },
        {
            id: 2,
            description: 'Indenizado'
        }
    ]
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [selectedDateRescision, setSelectedDateRescision] = useState('')
    const [selectedMotive, setSelectedMotive] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [selectedBalanceFGTS, setSelectedBalanceFGTS] = useState('')
    const [selectedNoticeDate, setSelectedNoticeDate] = useState('')

    useEffect(() => {

        setLoading(true)

        async function getEmployee() {
            try {
                const employeeData = await api.get(`api/v1/employees/company/${company}/branch/${branch}?status=1`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEmployeeList(employeeData?.data?.data)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        async function getReasonRescision() {
            try {
                const getReasons = await api.get(`api/v1/general/motivesDemission`, {
                    headers: {
                        Authorization: token
                    }
                })
                setReasonRescision(getReasons?.data?.data)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }

        getEmployee()
        getReasonRescision()
        setLoading(false)

    }, [])

    async function downloadFile(){
        setLoadingDownload(true)
        try {
            const file = await api.post(`api/v1/preview/termination/company/${company}/branch/${branch}`,{
                numcad: selectedEmployee?.employeeId,
                dateTermination: selectedDateRescision,
                motive: selectedMotive?.id,
                type: selectedType ? selectedType?.id : undefined,
                noticeDate: selectedNoticeDate ? selectedNoticeDate : undefined,
                balanceFGTS: selectedBalanceFGTS ? selectedBalanceFGTS : 0,
                pdf: true
            }, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `PreviaRescisão_${Number(new Date())}`)
            myElement.click()
            setLoadingDownload(false)
        } catch (error){
            setLoadingDownload(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Erro no download')
        }

    }

    return (
        <>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className='overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 h-[100vh]'>
                    <div className={`flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14 ${loadingDownload && 'cursor-wait'}`}>
                        <p className='text-lg md:text-xl font-semibold text-center justify-center text-primaryDefaultLight 2xl:mt-5'>Rescisão</p>
                        <div className={`flex flex-col w-fit h-[80%] sm:h-fit justify-center items-center shadow-xl rounded-lg sm:mt-5 2xl:mt-20 p-2 ${loadingDownload && 'cursor-wait'}`}>
                            <div className='flex flex-col justify-center h-full items-center p-3'>
                                <div className={`flex flex-col ${loadingDownload && 'cursor-wait'}`}>
                                    <label className=''>Colaborador *</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={employeeList}
                                            id={'employee'}
                                            preSelectedValue={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                            value={selectedEmployee ? selectedEmployee?.employeeName : ''}
                                            optionList={["employeeId", "employeeName"]}
                                            onChange={(e) => {
                                                setSelectedEmployee(e)
                                            }}
                                            selectedLabel={'employeeName'}
                                        />
                                    </LabelInput>
                                    <label className='mt-2'>Data de Rescisão *</label>
                                    <input
                                        type='date'
                                        className='w-72 sm:w-80 md:w-96 border border-gray-300 text-black h-8 px-2 rounded-md text-base mt-1 cursor-text'
                                        onChange={(e) => {
                                            setSelectedDateRescision(e?.target?.value)
                                        }}
                                        value={selectedDateRescision ? selectedDateRescision : ''}
                                    />
                                    <label className='mt-2'>Motivo de Rescisão *</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={reasonRescision}
                                            id={'reasons'}
                                            preSelectedValue={selectedMotive ? selectedMotive?.description : ''}
                                            value={selectedMotive ? selectedMotive?.description : ''}
                                            onChange={(e) => {
                                                setSelectedMotive(e)
                                            }}
                                            optionList={["id", "description"]}
                                            selectedLabel={'description'}
                                        />
                                    </LabelInput>
                                    <label className='mt-2'>Tipo de aviso prévio</label>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={typeEarlyWarning}
                                            id={'typeWarning'}
                                            preSelectedValue={selectedType ? selectedType?.description : ''}
                                            value={selectedType ? selectedType?.description : ''}
                                            onChange={(e) => {
                                                setSelectedNoticeDate('')
                                                setSelectedType(e)
                                            }}
                                            optionList={["id", "description"]}
                                            selectedLabel={'description'}
                                        />
                                    </LabelInput>
                                    {
                                        selectedType?.id == 1 ?
                                        <>
                                            <label className='mt-2'>Data do aviso *</label>
                                            <input
                                                type='date'
                                                className='w-72 sm:w-80 md:w-96 border border-gray-300 text-black h-8 px-2 rounded-md text-base mt-1 cursor-text'
                                                onChange={(e) => {
                                                    setSelectedNoticeDate(e?.target?.value)
                                                }}
                                                value={selectedNoticeDate ? selectedNoticeDate : ''}
                                            />
                                        </>
                                        : <></>
                                    }
                                    <label className='mt-2'>Saldo do FGTS *</label>
                                    <CurrencyInput id='originalValue' placeHolder='R$0,00'
                                        onChange={(value) => {
                                            setSelectedBalanceFGTS(parseFloat(value.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                        }}
                                        className='w-72 sm:w-80 lg:w-96 px-2 h-8 text-md text-black border border-gray-300 rounded-md'
                                    />
                                    <div className={`flex flex-col w-full items-center justify-center pb-1 mt-5 ${loadingDownload && 'cursor-wait'}`}>
                                        <div className={`mt-2 bg-primaryDefaultLight text-white w-52 ${loadingDownload ? 'cursor-wait' : 'cursor-pointer'} h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95`} 
                                            onClick={() => {downloadFile()}}
                                        >
                                            <FaPrint className='text-sm'/> Gerar PDF
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}