import React from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';
//pages
import AcceptInstructions from '../pages/instructions/acceptInstructions';

export default function NotAuthRoute() {
    return ( 
        <HashRouter> 
            <Routes>  
                <Route path="/" element={<AcceptInstructions />} />
                <Route path="/*" element={<AcceptInstructions />}/>
            </Routes>
        </HashRouter>
    )
}
