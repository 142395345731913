import React, { useState, useEffect } from "react";
import { useMenu } from "../../hooks/useMenu";
import { useModal } from '../../hooks/useModal';
import { TableDefault } from "../../components/table/table.default";
import { Toggle } from "../../components/toggle/toggleButton";
import api from "../../services/api";
import Loader from "../../components/Loader/loader";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import { FaDownload, FaEdit, FaExchangeAlt, FaTrash } from "react-icons/fa";
import Modal from '../../components/Modal/modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Button } from "../../components/buttons/button.default";
import { LabelInput } from "../../components/label/label.input";

export function Instructions() {
    const token = localStorage.getItem("@auth:token")
    const { show, setShow, setShowNotificationModal, setNotificationModalText } = useModal()
    const { setMenuTitle } = useMenu()
    setMenuTitle("Instruções da folha")
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const [loadingSelectedInstruction, setLoadingSelectedInstruction] = useState(false)
    const [detectModal, setDetectModal] = useState('')
    const [instructions, setInstructions] = useState([])
    const [selectedInstruction, setSelectedInstruction] = useState({})
    const [selectedInstructionId, setSelectedInstructionId] = useState(undefined)
    const tableThead = [
        {
            "name": "Baixar",
            "original_name": "download"
        },
        {
            "name": "Arquivo",
            "original_name": "originalFilename"
        },
        {
            "name": "Ativo",
            "original_name": "active"
        },
        {
            "name": "Criado em",
            "original_name": "created_at"
        },
        {
            "name": "Ações",
            "original_name": "actions"
        }
    ]
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [changeFile, setChangeFile] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!!selectedInstructionId) getInstructionById(selectedInstructionId)
    }, [selectedInstructionId])

    useEffect(() => {
        if (!show) {
            setDetectModal('')
            setChangeFile(false)
            setSelectedInstructionId(undefined)
        }
    }, [show])

    async function getData() {
        try {
            const getInstructions = await api.get('/api/v1/acceptInstructions', {
                headers: {
                    Authorization: token
                }
            })

            setInstructions(getInstructions.data?.data?.map(ins => {
                return {
                    download: <div className="w-full flex flex-col items-center justify-center">
                        <Tippy
                            content={'Baixar arquivo de instrução'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <div>
                                <p
                                    className="text-primaryDefaultLight hover:cursor-pointer"
                                    onClick={() => handleDownloadFile(ins?.id)}
                                >
                                    <FaDownload />
                                </p>
                            </div>
                        </Tippy>
                    </div>,
                    originalFilename: ins?.originalFilename,
                    active: ins?.active ? "Ativo" : "Inativo",
                    created_at: moment(ins?.created_at).utc(false).format("DD/MM/YYYY"),
                    actions: <div className="w-full flex flex-row items-center justify-center gap-4">
                        <Tippy
                            content={'Excluir registro de instrução'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <div>
                                <p
                                    className="text-red-700 hover:cursor-pointer"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        setShow(true); 
                                        setDetectModal('removeInstruction'); 
                                        setSelectedInstructionId(ins?.id);
                                    }}
                                >
                                    <FaTrash />
                                </p>
                            </div>
                        </Tippy>
                        <Tippy
                            content={'Atualizar registro de instrução'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <div>
                                <p
                                    className="text-primaryDefaultLight hover:cursor-pointer"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        setShow(true); 
                                        setDetectModal('editInstruction'); 
                                        setSelectedInstructionId(ins?.id);
                                    }}
                                >
                                    <FaEdit />
                                </p>
                            </div>
                        </Tippy>
                    </div>
                }
            }))
        } catch(error){
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    async function getInstructionById(instruction_id) {
        try {
            setLoadingSelectedInstruction(true)
            
            const instructionData = (await api.get(`/api/v1/acceptInstructions/${instruction_id}`, {
                headers: {
                    authorization: token
                }
            })).data?.data[0]
            setSelectedInstruction(instructionData)
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingSelectedInstruction(false)
        }
    }

    async function handleDownloadFile(instruction_id) {
        setLoading(true)
        try {
            const file = await api.get(`/api/v1/acceptInstructions/getFile/${instruction_id}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })

            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `Instrucao_Folha.${moment().utc(false).format("DD/MM/YYYY")}.pdf`)
            myElement.click()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }
    }

    async function handleCreateInstruction() {
        try {
            if (!selectedFile) {
                return alert("Informe um arquivo de instrução")
            }
            if (selectedFile?.type != 'application/pdf') {
                return alert("O arquivo deve ser do tipo pdf.")
            }
            setShow(false)
            setDetectModal('')
            setLoading(true)

            const formData = new FormData()
            formData.append('file', selectedFile)

            await api.post(`/api/v1/acceptInstructions`, formData, {
                headers: {
                    Authorization: token
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Instrução cadastrada com sucesso')
            await getData()
        } catch (error) {
            console.log(error)
            alert("Ocorreu um erro ao cadastrar nova instrução")
        } finally {
            setLoading(false)
        }
    }

    async function handleEditInstruction() {
        try {
            if (changeFile && !selectedFile) {
                return alert("Informe um arquivo de instrução")
            }
            if (changeFile && selectedFile?.type != 'application/pdf') {
                return alert("O arquivo deve ser do tipo pdf.")
            }
            setShow(false)
            setDetectModal('')
            setLoading(true)

            if (changeFile){
                const formData = new FormData()
                formData.append('file', selectedFile)
    
                await api.put(`/api/v1/acceptInstructions/updateFile/${selectedInstructionId}`, formData, {
                    headers: {
                        Authorization: token
                    }
                })
            }

            await api.put(`/api/v1/acceptInstructions/updateStatus/${selectedInstructionId}`, {
                active: selectedInstruction?.active
            }, {
                headers: {
                    Authorization: token
                }
            })
            
            await getData()

            setShowNotificationModal(true)
            setNotificationModalText('Instrução atualizada com sucesso')
            setShow(false)
        } catch (error) {
            console.log(error)
            alert("Ocorreu um erro ao atualizar instrução")
        } finally {
            setLoading(false)
        }
    }

    async function handleRemoveInstruction() {
        try {
            setShow(false)
            setDetectModal('')
            setLoading(true)

            await api.delete(`/api/v1/acceptInstructions/${selectedInstructionId}`, {
                headers: {
                    Authorization: token
                }
            })

            setShowNotificationModal(true)
            setNotificationModalText('Instrução removida com sucesso')
            await getData()
        } catch (error) {
            console.log(error)
            alert("Ocorreu um erro ao remover instrução")
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {
                show && detectModal === 'createInstruction' ?
                <Modal>
                    <div className={`
                        max-w-[30vw] min-w-[30vw] transition-all p-5 duration-300 bg-bgPrimaryLight rounded-lg max-h-[30rem] h-[30rem] 
                        overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400
                        flex flex-col justify-between
                    `}>
                        <div>
                            <h2 className="text-primaryDefaultLight text-lg font-semibold">Criação termo de instrução</h2>
                            <div className="max-w-full break-all py-6">
                                <div className="flex flex-col items-start gap-2">
                                    <p className="text-zinc-900 text-sm font-semibold">Selecione um arquivo (.pdf):</p>
                                    <div className='text-sm sm:text-sm flex flex-col items-start justify-center gap-[4px]'>
                                        <LabelInput>Arquivo *</LabelInput>
                                        <div className="w-full p-[6px] border border-gray-300 border-opacity-75 text-sm sm:text-sm rounded-lg">
                                            <input
                                                type="file"
                                                accept="application/pdf"
                                                className="text-black"
                                                onChange={(e) => setSelectedFile(e?.target?.files[0])}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-row items-center justify-center gap-6">
                            <Button approval={false} onClick={() => { setShow(false); setDetectModal('') } }>Cancelar</Button>
                            <Button approval={true} onClick={() => handleCreateInstruction()}>Confirmar</Button>
                        </div>
                    </div>
                </Modal> :
                    show && detectModal === 'editInstruction' ?
                    <Modal>
                        <div className={`
                            max-w-[30vw] min-w-[30vw] transition-all p-5 duration-300 bg-bgPrimaryLight rounded-lg max-h-[30rem] h-[30rem] 
                            overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400
                            flex flex-col justify-between
                        `}>                            
                            {
                                loadingSelectedInstruction ?
                                    <div className="py-6">
                                        <h2 className="text-zinc-900 text-base animate-pulse">Buscando...</h2>
                                    </div> :
                                    <>
                                        <div>
                                            <h2 className="text-primaryDefaultLight text-lg font-semibold">Edição termo de instrução</h2>
                                            <div className="max-w-full break-all py-6 flex flex-col items-start justify-start gap-6">
                                                {
                                                    changeFile ?
                                                    <div className="flex flex-col items-start gap-2">
                                                        <p className="text-zinc-900 text-sm font-semibold">Selecione um arquivo (.pdf):</p>
                                                        <div className='text-sm sm:text-sm flex flex-col items-start justify-center gap-[4px]'>
                                                            <LabelInput>Arquivo *</LabelInput>
                                                            <div className="w-full p-[6px] border border-gray-300 border-opacity-75 text-sm sm:text-sm rounded-lg">
                                                                <input
                                                                    type="file"
                                                                    accept="application/pdf"
                                                                    className="text-black"
                                                                    onChange={(e) => setSelectedFile(e?.target?.files[0])}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="flex flex-col items-start">
                                                        <p className="text-zinc-900 text-sm font-semibold">Arquivo selecionado:</p>
                                                        <div className="flex flex-row items-center gap-4">
                                                            <p>{selectedInstruction?.originalFilename}</p>
                                                            <Tippy
                                                                content={'Alterar arquivo'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='top'
                                                                delay={80}>
                                                                <div>
                                                                    <p
                                                                        className="text-primaryDefaultLight hover:cursor-pointer"
                                                                        onClick={async () => {
                                                                            await new Promise((_) => {
                                                                                setTimeout(() => setChangeFile(true), 100)
                                                                            })
                                                                        }}
                                                                    >
                                                                        <FaExchangeAlt />
                                                                    </p>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="flex flex-col items-start justify-start">
                                                    <p className="text-zinc-900 text-sm font-semibold">Status:</p>
                                                    <Tippy
                                                        content={<p>
                                                            { selectedInstruction?.active ? "Ativo" : "Inativo" }
                                                        </p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={80}>
                                                        <div className='flex justify-center items-center'>
                                                            <Toggle
                                                                status={selectedInstruction?.active ? true : false} 
                                                                onClick={() => setSelectedInstruction((prev) => ({ ...prev, active: selectedInstruction?.active ? false : true }))} 
                                                            />
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-row items-center justify-center gap-6">
                                            <Button approval={false} onClick={() => { setShow(false); setDetectModal('') } }>Cancelar</Button>
                                            <Button approval={true} onClick={() => handleEditInstruction()}>Confirmar</Button>
                                        </div>
                                    </>
                            }
                        </div>
                    </Modal> : 
                        show && detectModal === 'removeInstruction' ?
                        <Modal>
                            <div className={`relative min-w-[30vw] transition-all p-5 duration-300 bg-bgPrimaryLight rounded-lg max-h-[15rem] h-[15rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                                <div className="w-full h-full flex flex-col items-center justify-between py-16 gap-6">
                                    <h2 className="text-zinc-900 text-lg">Deseja remover esse termo de instrução?</h2>
                                    <div className="flex flex-row items-center gap-6">
                                        <Button approval={false} onClick={() => { setShow(false); setDetectModal('') } }>Cancelar</Button>
                                        <Button approval={true} onClick={() => handleRemoveInstruction()}>Confirmar</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal> :
                            <></>
            }
            <div className={loading ? 'flex h-[70%] items-center justify-center' : ''}>
                {
                    loading ?
                    <Loader/>
                    :
                    <div className="animate-[wiggleLeft_1s_ease-in-out] conteudo">
                        <div className="hidden sm:flex flex-col justify-start align-center ml-20 w-[100%] h-[92vh] mr-8 gap-5">
                            <TableDefault
                                title={tableThead}
                                data={instructions}
                                quantity={instructions.length}
                                collumns={["download", "originalFilename", "active", "created_at", "actions"]}
                                cursorPointer={false}
                                buttonFunction={user?.isSuport ? (e) => { setShow(true); setDetectModal('createInstruction') } : ''}
                                buttonFunctionParam={user?.isSuport ? true : ''}
                                buttonDescription={user?.isSuport ? 'Nova' : ''}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )

}
