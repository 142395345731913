import React, { useState, useEffect } from 'react'
import api from '../../services/api'

/**
 * Função que cria tabela com totais.
 * @component
 * @param {Object} props - Objeto contendo filtros.
 * @param {string} props.endpoint // enpoint da api que deve ser chamada
 * @param {string} props.token // token de autenticação
 * @param {string[]} props.mapApiData - mapeia onde está o conteudo da tabela/body no objeto da resposta
 * @param {Object} props.filters - campo a ser filtrado.
 * @param {string[]} props.mapTotalPages
 * @param {string} props.objectChange - Para identificar qual atributo percorrer caso seja pesquisado a informação
 * @returns {JSX.Element} Um componente de tabela personalizado.
 */
export function InputDinamicAutoComplete({
    id, 
    onChange, 
    placeHolder, 
    selectedLabel, 
    preSelectedValue, 
    optionList, 
    width, 
    height, 
    value, 
    inactive, 
    disabled, 
    autoFocus, 
    typeInactive, 
    onBlur, 
    endpoint,
    token,
    filters,
    mapApiData = [],
    mapTotalPages = [],
    objectChange
}) {

    const [showThisList, setShowThisList] = useState(null)
    const [showList, setShowList] = useState(false)
    const [selectedValue, setSelectedValue] = useState(value ? value : '')
    const [mouseHover, setMouseHover] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const widthInput = width > 0 ? width : 96
    const heightList = height > 0 && height + 'px'
    const [ loading, setLoading ] = useState(false)
    const [ loadingSearch, setLoadingSearch ] = useState(false)
    const [ initialFilter, setInitialFilter ] = useState(filters)
    const [ filterChange, setFilterChange ] = useState(filters)
    const [ page, setPage ] = useState(1)
    const [ data, setData ] = useState([])
    const [ totalPages, setTotalPages ] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [clearInput, setClearInput] = useState(false)

    async function handleSelectedValue(dataValue, selectedLabel) {

        setMouseHover(true)
        setSelectedValue(dataValue[selectedLabel])
        setShowThisList(null)
        onChange(dataValue)
        await new Promise(resolve => setTimeout(resolve, 300))
        setMouseHover(false)

    }

    function handleSelectValueDefault(value) {

        const valueSelected = data.filter((dataValue) => {
            if (dataValue[selectedLabel] === value) {
                return dataValue
            }
        })
        onChange(valueSelected[0])
    }

    useEffect(() => {
        if(filterChange && page && endpoint && token){
            getData()
        }
    }, [page, filterChange, endpoint, token])

    useEffect(() => {
        setSelectedValue(preSelectedValue)
    }, [])

    async function getData(){
        setLoading(true)
        try {
            const filter = formatFilter()
            
            const api_endpoint = endpoint + `?page=${page}&` + filter
            const response = await api.get(api_endpoint, {
                headers: {
                    Authorization: token
                }
            })

            mapAndSetTableData(response.data)
            mapAndSetTotalPages(response.data)
            setLoading(false)            
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        const listOptions = document.getElementById('user-select-options')

        if (showThisList && listOptions) {
            listOptions.style.opacity = '1'
        } else if (listOptions) {
            listOptions.style.opacity = '0'
        }
    }, [showThisList])

    const handleBlur = () => {
        setShowThisList(null)
        onBlur && onBlur()
    }

    function formatFilter(){
        const formatFilter = []
        if(Object.keys(filterChange)?.length > 0) {
            Object.keys(filterChange)?.map(filter => {
                formatFilter.push(`${filter}=${filterChange[filter]?.toString()?.replace(' ', '%')}`)
            })
        }
        return formatFilter.length > 0 ? formatFilter.join('&') : ''
    }

    function mapAndSetTableData(apiResponseData){
        if(mapApiData.length > 0){ // mapeia no objeto da resposta o DATA
            const value = utilsMapObjectFinder(apiResponseData, mapApiData)
            if (!inputValue){    
                if (clearInput){
                    setData([...value])
                    setClearInput(false)
                } else {
                    setData([...data, ...value])
                }         
            } else {
                if (page == 1) {
                    setData(value)
                } else {
                    setData([...data, value])
                }
            }
        } else { // default se não informar
            if (!inputValue){
                if (clearInput){
                    setData([...apiResponseData.data])
                    setClearInput(false)
                } else {
                    setData([...data, ...apiResponseData.data])
                }
            } else {
                if (page == 1){
                    setData(apiResponseData.data)
                } else {
                    setData([...data, ...apiResponseData.data])
                }
            }
        }
    }

    function mapAndSetTotalPages(apiResponseData){
        if(mapTotalPages.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapTotalPages)
                setTotalPages(value)
            } catch (error) {
                setTotalPages(null)
                console.error('NewTableDefault, property: mapTotalPages -', error.message)
            }
        }
    }

    function utilsMapObjectFinder(object, mapSequence){
        let value = object
        for (const key of mapSequence) {
            if (value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                throw new Error(`Chave '${key}' não encontrada nos dados.`)
            }
        }
        return value
    }

    useEffect(() => {
        if (inputValue && inputValue?.length > 3) {
            setLoadingSearch(true)
        
            const timeoutId = setTimeout(() => {
                handleDelayedFunction(inputValue)
                setPage(1)
                setLoadingSearch(false)
            }, 3000)
        
            return () => clearTimeout(timeoutId)
        } else {                        
            setPage(1)
            setFilterChange({...initialFilter})
            setLoadingSearch(false)
        }
    }, [inputValue])
    
    const handleDelayedFunction = (value) => {
        const obj = {[objectChange]: value}
        setFilterChange({...filterChange, ...obj})
    }

    return (
        <React.Fragment key={'container_input_dinamic'}>
            <React.Fragment key={'input_dinamic'} className='hidden sm:flex flex-col scroll-smooths relative'>
                <input disabled={inactive || disabled ? true : false}
                    autoFocus={autoFocus ? true : false}
                    autoComplete="off" type='text'
                    id={id}
                    value={value ? value : selectedValue}
                    onFocus={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onClick={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onBlur={() => !mouseHover && handleBlur()}    
                    onChange={(e) => {
                        !typeInactive && setSelectedValue(e.target.value); !typeInactive && onChange(e.target.value); !typeInactive && setShowList(false)
                        setInputValue(e.target.value)   
                        if (!e.target.value) {setClearInput(true)}
                    }}
                    datavalue='text'
                    className={`pl-2 text-lg sm:text-sm border border-titleGrayTextDark
                     focus:bg-titleGrayTextLight h-16 sm:h-8
                     outline-none transition-all duration-200 focus:shadow-borderShadow w-${widthInput} ${inactive || disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}`}
                />
                {
                    showThisList === 1 &&
                    <ul id='user-select-options' style={{ maxHeight: heightList?.length > 0 ? heightList : '200px', overflowX: 'hidden' }} onMouseMove={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} className={`opacity-0 border border-gray-300 rounded-sm dark:scrollbar-thin dark:scrollbar-track-zinc-400 dark:scrollbar-thumb-zinc-300  transition-all duration-300 flex z-30 bg-bgPrimaryLight boxShadow flex-col items-start absolute translate-y-20 sm:translate-y-0 w-${widthInput}`}>
                        {
                            data?.length === 0 ?
                            <li className={`p-2 scroll-smooth bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight' cursor-not-allowed w-full transition-all duration-300`}>
                                Sem dados
                            </li>
                            :
                            <>
                                {
                                    loadingSearch ? 
                                    <div key={'loading-list'} className={`p-1 scroll-smooth w-full flex items-center justify-center transition-all duration-300`}>
                                        <p className='text-primaryDefaultLight animate-pulse'>Carregando...</p>
                                    </div>
                                    : 
                                    <>
                                        {
                                            data.map((dataValue, index) => {                                                
                                            return (
                                                <li id={`li-option-${index}`} key={index} className={`p-2 hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark focus:bg-bgSecondaryLight dark:focus:bg-secondaryDefaultDark scroll-smooth ${cursor === index ? 'bg-bgSecondaryLight dark:bg-secondaryDefaultDark dark:text-titleGrayTextLight ' : 'bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight'} cursor-pointer w-full transition-all duration-300`} 
                                                    onClick={() => handleSelectedValue(dataValue, selectedLabel)}
                                                    onTouchStart={() => handleSelectedValue(dataValue, selectedLabel)}
                                                >
                                                    <a className='sm:text-sm text-2xl text-primaryDefaultLight' selectedData={JSON.stringify(dataValue)} value={
                                                        dataValue[selectedLabel]}>{optionList?.length > 0 ? optionList.map((option, index) => {
                                                            if (index < optionList.length - 1) {
                                                                return placeHolder !== undefined ?
                                                                    dataValue[option] + placeHolder : dataValue[option] + ' - '
                                                            }
                                                            return dataValue[option]
                                                        }) : dataValue[selectedLabel]
                                                        }
                                                    </a>
                                                </li>
                                            )})
                                        }
                                        {
                                            page < totalPages &&
                                            <div onClick={() => setPage(page + 1)} id={`li-option-more`} key={'li-option-more'} className={`p-1 scroll-smooth cursor-pointer w-full flex items-center justify-center transition-all duration-300`}>
                                                <p className='text-primaryDefaultLight underline'>Mostrar mais</p>
                                            </div>
                                        }                                        
                                    </>
                                }
                            </>
                        }
                    </ul>
                }
            </React.Fragment>
            <div className='flex sm:hidden'>
                <select onChange={(e) => { setSelectedValue(e.target.value); 
                    handleSelectValueDefault(e.target.value); setShowList(false) }} 
                    className="bg-gray-50 border dark:bg-secondaryDefaultDark border-gray-300 dark:border-primaryBorderDark 
                    text-gray-900 dark:text-titleGrayTextDark h-10 text-sm outline-none focus:ring-blue-500 
                    focus:border-primaryDefaultLight block w-full md:w-96 p-2.5">
                    {
                        !selectedValue &&
                        <option selected={true}>-Selecione-</option>
                    }
                    {data.map((dataValue, index) => {
                        return <option selected={!selectedValue ? false : selectedValue === dataValue[selectedLabel] ? selectedValue : false} >{dataValue[selectedLabel]}</option>
                    })}
                </select>
            </div>
        </React.Fragment>
    )
}