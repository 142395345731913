import React, { useEffect, useState } from 'react'
// import moment from 'moment/locale/pt-br'
import { FaArrowDown, FaArrowUp, FaArrowsAltV, FaQuestion, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { Button } from '../buttons/button.default'
//components
import { Tooltip } from '../toolTip/toolTip'
import moment from 'moment'

export function TableDefault({ title, data, collumns, onClick, width, buttonFunction, buttonFunctionParam, buttonDescription, icon, cursorPointer,customFunctions, quantity, id, isDangerously = false, hasMarginBottom = true }) {

    const [sortBy, setSortBy] = useState('')
    const [sortTo, setSortTo] = useState(true)
    const [tableData, setTableData] = useState(data)
    const [startFilter, setStartFilter] = useState(false)
    const [filteredValue, setFilteredValue] = useState(null)

    function sortTable(sortBy) {

        let tableToSort = tableData
        let sortedArray

        // * Ordenação do tipo data
        if (sortBy == 'admissionDate' || sortBy == 'demissionDate'){
            sortedArray = sortTo ? tableToSort.sort((a, b) => {
                const dateA = new Date(moment(a[sortBy], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                const dateB = new Date(moment(b[sortBy], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                return dateA - dateB;
            }) : tableToSort.sort((a, b) => {
                const dateA = new Date(moment(a[sortBy], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                const dateB = new Date(moment(b[sortBy], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                return dateB - dateA;
            });
        } else { // * Ordenação do tipo string e outros
            sortedArray = sortTo ? tableToSort.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1) : tableToSort.sort((a, b) => (b[sortBy] > a[sortBy]) ? 1 : -1)
        }

        setTableData([...sortedArray])
        filterTable()

    }

    useEffect(() => {

        setTableData([...data])
        filterTable()

    }, [data])

    function filterTable() {

        let filterHasSpace = filteredValue?.indexOf(' ')
        let filterToSearch = filteredValue
        let filteToSearArray = []

        if (filterHasSpace > - 1) {
            while (filterHasSpace !== -1) {
                filteToSearArray.push(filterToSearch.slice(0, filterHasSpace + 1))
                filterToSearch = filterToSearch.replace(filterToSearch.slice(0, filterHasSpace + 1), '')
                filterHasSpace = filterToSearch?.indexOf(' ')
                if (filterHasSpace === - 1 && filterToSearch?.length > 0) {
                    filteToSearArray.push(filterToSearch)
                }
            }
        } else if (filterHasSpace === - 1) {
            filteToSearArray.push(filterToSearch)
        }
        if (tableData?.length === 0 || filteToSearArray?.length > 0) {

            const filter = tableData?.filter((dataOfTable, index) => {
                let collumNames = Object.keys(dataOfTable)

                const filterWords = filteToSearArray.filter((wordToCheck) => {

                    let wordFinded = false

                    const collumnFilter = collumNames.filter((wordInCollumn) => {
                        let wordToSearch = wordToCheck.toString()?.toLowerCase().replace(' ', '')
                        if (!wordFinded && tableData[index][wordInCollumn]?.toString()?.toLowerCase().indexOf(wordToSearch) > -1) {
                            wordFinded = true
                            return wordInCollumn
                        }
                    })

                    if (collumnFilter.length > 0) {
                        return wordToCheck
                    }
                })
                if (filterWords?.length > 0 && (filterWords?.length === filteToSearArray?.length)) {
                    return dataOfTable
                }
            })
            if (filter?.length > 0) {
                return filter
            } else {
                return tableData
            }
        }
    }

    useEffect(() => {

        if (startFilter) {
            filterTable()
        }

    }, [startFilter])

    return (
        <div class={`m-0 p-0 overflow-x w-full overflow-y-auto dark:border dark:border-secondaryBorderDark ${hasMarginBottom ? 'mb-20' : 'mb-0'} shadow-md shadow-gray-500 h-max-[80vh] scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500`}>

            <table className={`border-collapse table-auto overflow-y-auto w-full`} id={id}>
                <thead className='top-0 sticky bg-gray-500'>
                    <tr className='bg-gray-500'>
                        <th colSpan={title?.length} className='bg-[#ececec] text-sm text-white font-extrabold h-12'>
                            <div className='flex flex-row gap-2 items-center justify-start pl-6 pt-3 pb-3 h-full border border-b-gray-300'>
                                <input onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        setStartFilter(true)
                                    }
                                }} type='text' placeholder='Filtrar' onChange={(e) => { if (e.target.value?.length === 0) { setStartFilter(false) }; setFilteredValue(e.target.value) }} className='px-2 py-1 outline-none bg-white border border-gray-300 text-primaryDefaultLight brightness-90 placeholder:text-primaryDefaultLight'></input>
                                <div title='Pesquisar' tabIndex={0} onKeyDown={(e) => { if (filteredValue?.length > 0 && e.code === 'Enter') { setStartFilter(true) } }} className='p-1 rounded-lg border border-transparent focus:border focus:border-secondaryDefaultLight outline-none cursor-pointer' onClick={() => { if (filteredValue?.length > 0) { setStartFilter(true) } }}>
                                    <FaSearch className='text-primaryDefaultLight'/>
                                </div>
                                <div className='flex justify-end w-[100%] pr-5'>
                                    {
                                        quantity ?
                                        <p className='text-sm text-primaryDefaultLight flex justify-center items-center mr-4'>{quantity} Registros</p>
                                        :
                                        <></>
                                    }
                                    {
                                        !buttonFunction ? <></> : <Button shadow={true} onClick={() => {
                                            if(buttonFunctionParam){
                                                buttonFunction(buttonFunctionParam)
                                            }else{
                                                buttonFunction()
                                            }
                                        }}>{ buttonDescription }</Button>
                                    }
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr className=''>
                        {
                            title?.map((titleColumn, indexTh) => {
                                return <th onClick={(e) => { sortTable(titleColumn?.original_name); setSortBy(titleColumn?.original_name); setSortTo(!sortTo) }} key={indexTh} className='bg-[#ececec] text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-10 top-12 sticky'>
                                    <div className='flex flex-row gap-1 items-center justify-center cursor-pointer text-primaryDefaultLight px-2 border border-b-gray-300 h-full border-x-transparent'>
                                        {titleColumn?.name}
                                        {
                                            sortBy !== titleColumn?.original_name ?
                                                <RiArrowUpDownLine size={'14'} />
                                                :
                                                sortTo ?
                                                    <FaArrowUp size={'10'} />
                                                    :
                                                    <FaArrowDown size={'10'} />
                                        }
                                    </div>
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody className=''>

                    {
                        tableData?.length === 0 ?
                            <tr key={'1'} className='hover:bg-gray-300 dark:hover:bg-hoverDark transition-all duration-200'>
                                <td colSpan={title.length} key={`1-1`} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-primaryDefaultLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                            </tr>
                            :
                            startFilter && filterTable()?.length === undefined ?
                                <tr key={'1'} className='hover:bg-gray-300 hover:text-secondaryDefaultLight dark:hover:bg-hoverDark transition-all duration-200'>
                                    <td colSpan={title.length} key={`1-1`} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-primaryDefaultLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                </tr>
                                :
                                startFilter ?
                                    filterTable().map((apiData, index) => {
                                        return <tr key={index} className={`hover:bg-gray-300 hover:text-secondaryDefaultLight dark:hover:bg-hoverDark transition-all duration-200 ${index % 2 == 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                            {
                                                collumns?.map((collumn, indexCollum) => {
                                                    if (isDangerously){
                                                        return <td dangerouslySetInnerHTML={{ __html: apiData[collumn] }} key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}></td>
                                                    }
                                                    return <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>

                                                })
                                            }
                                        </tr>
                                    })
                                    :
                                    tableData.map((apiData, index) => {

                                        return <tr key={index} className={`hover:bg-gray-300 transition-all duration-200 ${index % 2 == 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                            {
                                                collumns?.map((collumn, indexCollum) => {
                                                    if (isDangerously){
                                                        return (customFunctions?.isValid && customFunctions?.collumn.includes(indexCollum)) ? 
                                                        <td dangerouslySetInnerHTML={{ __html: apiData[collumn] }} key={`${indexCollum}-${index}`} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}></td>
                                                        :
                                                        <td dangerouslySetInnerHTML={{ __html: apiData[collumn] }} key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}></td>
                                                    }
                                                    return (customFunctions?.isValid && customFunctions?.collumn.includes(indexCollum)) ? 
                                                    <td key={`${indexCollum}-${index}`} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                    :
                                                    <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                })
                                            }
                                        </tr>
                                    })
                    }
                </tbody>
            </table>
        </div >
    )
}