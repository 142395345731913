import React from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { ProtectedRoute } from './protectedRouterValidation';
//pages
import NotFound from '../pages/notFound/notFound';
import History from '../pages/history/history';
import HealthPlanPeriods from '../pages/healthPlan/healthPlanPeriods';
import HealthPlan from '../pages/healthPlan/healthPlan';
import HomePage from '../pages/homepage/homepage';
import SendEvents from '../pages/sendEvents/sendEvents';
import ConsultPeriod from '../pages/consultPeriod/consultPeriod';
import Users from '../pages/users/users';
import LinkCompany from '../pages/users/linkCompany';
import Param from '../pages/parameterization/param';
import RequestAdmission from '../pages/employee/requestAdmission';
import RequestAdmissionImport from '../pages/employee/requestAdmissionImport';
import EmployeeConsult from '../pages/employee/employeeConsult';
import ConfirmedReadFile from '../pages/employee/confirmedReadFilePerEmail';
import EmployeeConsultPendings from '../pages/employee/employeeConsultPendings';
import EmployeeConsultAdmissionsPendings from '../pages/employee/employeeConsultAdmissionsPendings';
import EmployeeConsultDetail from '../pages/employee/consultDetail';
import DetailEmployeeDocs from '../pages/employee/detailEmployeeDocs';
import ResetPassword from '../pages/resetPassword/resetPassword';
import UseTermsConfig from '../pages/termsOfUse/termsOfUseConfig';
import { ParameterizationAverage } from '../pages/average/parameterizationAverage';
import { HistoryAverage } from '../pages/average/historyAverage';
import { SendAverage } from '../pages/average/sendAverage';
import { ConsultPeriodAverage } from '../pages/average/consultPeriodAverage';
import { CompanyUser } from '../pages/companyUsers/companyUser';
import { Dashboard } from '../pages/dashboard/dashboard';
import { DashboardAdmin } from '../pages/dashboard/dashboardAdmin';
import { SalaryIncrease } from '../pages/previews/salaryIncrease';
import { SalaryIncreaseSingle } from '../pages/previews/salaryIncreaseSingle';
import { ReportEmployessHistoric } from '../pages/reports/employeesHistoric';
import { ReportEmployessBirthday } from '../pages/reports/employeesBirthday';
import { ReportCompanyBirthday } from '../pages/reports/companyBirthday';
import { ReportEmployessEndContracts } from '../pages/reports/employeesEndContracts';
import { ReportEmployessEndHolidays } from '../pages/reports/employeesEndHolidays';
import { ReportEmployessData } from '../pages/reports/employeesData';
import { ReportEmployeesHoliday } from '../pages/reports/employeesHolidaysPerPeriod';
import { ReportEmployeesWorkingPerDate } from '../pages/reports/employeesWorkingPerDate';
import { ReportEmployeesWithDependents } from '../pages/reports/employeesWithDependents';
import { ReportCostEmployees } from '../pages/reports/costEmployees';
import { ReportNotes } from '../pages/reports/notesReport';
import { ReportIsonomySalary } from '../pages/reports/isonomySalary';
//utils
//components
import Menu from '../components/Menu/menu';
import Body from '../components/Body/body';
//hooks
import { MenuProvider } from '../hooks/useMenu';
import { ModalProvider } from '../hooks/useModal';
import { NotificationModal } from '../components/NotificationModal/notificationModal';
import { Rescision } from '../pages/previews/rescision';
import { Holiday } from '../pages/previews/holiday';
import { ReportAbsenteeismTurnover } from '../pages/reports/absenteeismTurnover';
import { CompanyConfig } from '../pages/companyConfig/companyConfig';
import { ConferenceSends } from '../pages/conferences/conferenceSends';
import { EventsConfigDefault } from '../pages/eventsConfigDefault/eventsConfigDefault';
import { EventsConfig } from '../pages/eventsConfig/eventsConfig';
import { Notes } from '../pages/notes/notes';
import { TypesNotes } from '../pages/typesNotes/typesNotes';
import { Companies } from '../pages/companies/companies';
import { Release } from '../pages/release/release';
import { ReleaseCreate } from '../pages/release/releaseCreate';
import { Instructions } from '../pages/instructions/instructions';

export default function AppRoute() {
    return (
        <HashRouter>
            <MenuProvider>
                <ModalProvider>
                    <Menu />
                    <NotificationModal />
                    <Body>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/resetPassword' element={<ResetPassword />} />
                            <Route path='/employeeAdmission' element={<RequestAdmission />} />
                            <Route path='/employeeAdmissionSave/:save_id' element={<RequestAdmission />} />
                            <Route path='/history' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <History />
                                </ProtectedRoute>
                            } />
                            <Route path='/healthPlanPeriods' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <HealthPlanPeriods />
                                </ProtectedRoute>
                            } />
                            <Route path='/healthPlan/:id' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <HealthPlan isConsult={false} />
                                </ProtectedRoute>
                            } />
                            <Route path='/healthPlan/consult/:id' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <HealthPlan isConsult={true} />
                                </ProtectedRoute>
                            } />
                            <Route path='/sendEvents' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <SendEvents />
                                </ProtectedRoute>
                            } />
                            <Route path='/consultPeriod' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ConsultPeriod />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeConsult' element={
                                <ProtectedRoute permissions={[1, 2, 3, 4]}>
                                    <EmployeeConsult />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeConsultDetail/:idSenior' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <EmployeeConsultDetail />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeConsultDetail/originConnect/:idFolpagConnect' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <EmployeeConsultDetail />
                                </ProtectedRoute>
                            } />
                            <Route path='/useTerms' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <UseTermsConfig />
                                </ProtectedRoute>
                            } />
                            <Route path='/historyAverage' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <HistoryAverage />
                                </ProtectedRoute>
                            } />
                            <Route path='/sendAverage' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <SendAverage />
                                </ProtectedRoute>
                            } />
                            <Route path='/consultPeriodAverage' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ConsultPeriodAverage />
                                </ProtectedRoute>
                            } />
                            <Route path='/confirmedRead/:uuid/:company/:branch/:companyName/:branchCnpj' element={
                                <ProtectedRoute permissions={[2, 3]}>
                                    <ConfirmedReadFile />
                                </ProtectedRoute>
                            } />
                            <Route path='/dashboard' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <Dashboard />
                                </ProtectedRoute>
                            } />
                            <Route path='/previews' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <SalaryIncrease />
                                </ProtectedRoute>
                            } />
                            <Route path='/previewSalarySingle' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <SalaryIncreaseSingle />
                                </ProtectedRoute>
                            } />
                            <Route path='/previewsRescision' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <Rescision />
                                </ProtectedRoute>
                            } />
                            <Route path='/previewsHoliday' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <Holiday />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsEmployeesHistoric' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployessHistoric />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsEmployeesBirthday' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployessBirthday />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsEmployeesEndContracts' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployessEndContracts />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsEmployeesEndHolidays' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployessEndHolidays />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsEmployeesData' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployessData />
                                </ProtectedRoute>
                            } />
                            <Route path='/reportsAbsenteeismTurnover' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportAbsenteeismTurnover />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsEmployeesHolidaysPerPeriod' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployeesHoliday />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsEmployeesWorking' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployeesWorkingPerDate />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsEmployeesWithDependents' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportEmployeesWithDependents />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsCostEmployees' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportCostEmployees />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsNotes' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportNotes />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsCompanyBirthday' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportCompanyBirthday />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/reportsIsonomySalary' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <ReportIsonomySalary />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/companyConfig' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <CompanyConfig />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/notes' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <Notes />
                                </ProtectedRoute>
                            }></Route>
                            <Route path='/typesNotes' element={
                                <ProtectedRoute permissions={[1, 2, 3]}>
                                    <TypesNotes />
                                </ProtectedRoute>
                            }></Route>
                            {/* Rotas do RH e ADM */}
                            <Route path='/eventsConfig' element={
                                <ProtectedRoute ProtectedRoute permissions={[1,2]}>
                                    <EventsConfig />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeAdmission/:idFolpagConnect' element={
                                <ProtectedRoute permissions={[1, 2]}>
                                    <RequestAdmissionImport />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeConsultPendings' element={
                                <ProtectedRoute permissions={[1, 2]}>
                                    <EmployeeConsultPendings />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeDocs' element={
                                <ProtectedRoute permissions={[1, 2, 3, 4]}>
                                    <DetailEmployeeDocs />
                                </ProtectedRoute>
                            } />
                            <Route path='/employeeConsultAdmissionsPendings' element={
                                <ProtectedRoute permissions={[1, 2, 4]}>
                                    <EmployeeConsultAdmissionsPendings />
                                </ProtectedRoute>
                            } />
                            <Route path='/parameterization' element={
                                <ProtectedRoute permissions={[1, 2]}>
                                    <Param />
                                </ProtectedRoute>
                            } />
                            <Route path='/parameterizationAverage' element={
                                <ProtectedRoute permissions={[1, 2]}>
                                    <ParameterizationAverage />
                                </ProtectedRoute>
                            } />
                            <Route path='/conferenceSends' element={
                                <ProtectedRoute permissions={[1, 2]}>
                                    <ConferenceSends />
                                </ProtectedRoute>
                            } />
                            {/* Rotas Somente do ADM */}
                            <Route path='/users' element={
                                <ProtectedRoute ProtectedRoute permissions={[1]}>
                                    <Users />
                                </ProtectedRoute>
                            } />
                            <Route path='/users/:id/:username/:fullname' element={
                                <ProtectedRoute permissions={[1]}>
                                    <LinkCompany />
                                </ProtectedRoute>
                            } />
                            <Route path='/companyUser' element={
                                <ProtectedRoute permissions={[1]}>
                                    <CompanyUser />
                                </ProtectedRoute>
                            } />
                            <Route path='/eventsConfigDefault' element={
                                <ProtectedRoute ProtectedRoute permissions={[1]}>
                                    <EventsConfigDefault />
                                </ProtectedRoute>
                            } />
                            <Route path='/companies' element={
                                <ProtectedRoute ProtectedRoute permissions={[1,2]}>
                                    <Companies />
                                </ProtectedRoute>
                            } />
                            <Route path='/dashboardAdmin' element={
                                <ProtectedRoute permissions={[1]}>
                                    <DashboardAdmin />
                                </ProtectedRoute>
                            } />
                            <Route path='/release' element={
                                <ProtectedRoute permissions={[1]}>
                                    <Release />
                                </ProtectedRoute>
                            } />
                            <Route path='/releaseCreate' element={
                                <ProtectedRoute permissions={[1]}>
                                    <ReleaseCreate />
                                </ProtectedRoute>
                            } />
                            <Route path='/instructions' element={
                                <ProtectedRoute permissions={[1]}>
                                    <Instructions />
                                </ProtectedRoute>
                            } />
                            <Route path='/404' element={<NotFound />} />
                            <Route path='/*' element={<NotFound />} />
                        </Routes>
                    </Body>
                </ModalProvider>
            </MenuProvider>
        </HashRouter>
    );

}
