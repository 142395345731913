import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../services/api'
import { useMenu } from '../../hooks/useMenu'
import { useCompanyBranch } from '../../hooks/useCompanyBranch'
import Loader from '../../components/Loader/loader'
import moment from 'moment'
import { cpfMask } from '../../utils/formatText/cpfMask'
import { cnpjMask } from '../../utils/formatText/cnpjMask'

import './requestAdmission.css'
import { reportStyle } from './reportStyle'
import { pisMask } from '../../utils/formatText/pisMask'
import { maskCep } from '../../utils/formatText/cepMask'
import Modal from '../../components/Modal/modal'
import { useModal } from '../../hooks/useModal'
import { FiChevronRight } from 'react-icons/fi'
import { useAuth } from '../../hooks/useAuth'
import { Input } from '../../components/input/input'

export default function EmployeeConsultDetail(){

    const { idSenior, idFolpagConnect } = useParams()
    const idEmployeeSenior = idSenior;
    const idEmployeeFolpagConnect = idFolpagConnect;
    
    const token = localStorage.getItem('@auth:token')
    const navigate = useNavigate()
    const { setShow, show } = useModal()
    const { user } = useAuth()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [ selectedForm, setSelectedForm ] = useState(1)
    const [ loading, setLoading ] = useState(true)
    const [ insideLoading, setInsideLoading ] = useState(false)
    const { setMenuTitle, menuStatus } = useMenu()
    const { company, branch, companyName, branchCnpj } = useCompanyBranch()
    setMenuTitle('Consulta Completa')
    const [filteredStatus, setFilteredStatus] = useState(idEmployeeFolpagConnect ? 2 : 1) // 1 = Senior | 2 = Enviados (Connect)
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [banks, setBanks] = useState([])
    const [nationalities, setNationalities] = useState([])
    
    // Api Datas
    const [ employeeData, setEmployeeData ] = useState('')
    const [ dependentsData, setDependentsData ] = useState([])

    useEffect(() => {
        
        async function getDatas(){
            setLoading(true)
            try{
                let idConnect
                if (filteredStatus == 2 && !idEmployeeFolpagConnect && idEmployeeSenior){
                    const getIdConnect = await api.get(`api/v1/employees/getIdConnect/company/${company}/branch/${branch}/employee/${idEmployeeSenior}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    idConnect = getIdConnect?.data?.data
                }

                const employee = await api.get(`api/v1/employees/company/${company}/branch/${branch}/employee/${idEmployeeFolpagConnect ? idEmployeeFolpagConnect : idConnect ? idConnect : idEmployeeSenior}?origin=${filteredStatus}`, {
                    headers: {
                        authorization: token
                    }
                })

                if ((idEmployeeFolpagConnect || idConnect) && !idEmployeeSenior){
                    let getCities = []
                    let getDistricts = []
                    if (employee?.data?.data?.addressCountry && employee.data.data.addressState){
                        getCities = await api.get(`api/v1/geo/cities/country/${employee?.data?.data?.addressCountry}/state/${employee.data.data.addressState}`, {
                            headers: {
                                authorization: token
                            }
                        })
                        setCities(getCities.data.data)
        
                        getDistricts = await api.get(`api/v1/geo/districts/country/${employee?.data?.data?.addressCountry}/state/${employee.data.data.addressState}/city/${employee.data.data.addressCity}`, {
                            headers: {
                                authorization: token
                            }
                        })
                        setDistricts(getDistricts.data.data)
                    }

                    const getBanks = await api.get('api/v1/employees/banks', {
                        headers: {
                            authorization: token
                        }
                    })
                    setBanks(getBanks.data.data)

                    const getNationalities = await api.get(`api/v1/geo/nationalities`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setNationalities(getNationalities.data.data)
                    
                    if (employee?.data?.data?.employeeBank){
                        employee.data.data.employeeBank = getBanks.data.data.filter(bank => bank?.bank_id == employee?.data?.data?.employeeBank)[0]?.bank_name
                    }

                    if (employee?.data?.data?.addressDistrict){
                        employee.data.data.addressDistrict = getDistricts.data.data.filter(district => district?.district_id == employee?.data?.data?.addressDistrict)[0]?.district_name
                    }

                    if (employee?.data?.data?.addressCity){
                        employee.data.data.addressCity = getCities.data.data.filter(city => city?.city_id == employee?.data?.data?.addressCity)[0]?.city_name
                    }

                    if (employee?.data?.data?.nationality){
                        employee.data.data.nationality = getNationalities.data.data.filter(nati => nati?.nationality_id == employee?.data?.data?.nationality)[0]?.nationality_name  
                    }
                    if (employee?.data?.data?.addressCountry == 1) {employee.data.data.addressCountry = 'Brasil'}
                }

                if (employee?.data?.data?.codSelection && employee?.data?.data?.codSelection == 5){
                    employee.data.data.valueGratification = (employee?.data?.data?.employeeSalary * 0.4)?.toFixed(2)
                }
                setEmployeeData(employee.data.data)

                const dependents = await api.get(`api/v1/employees/company/${company}/branch/${branch}/employee/${idEmployeeFolpagConnect ? idEmployeeFolpagConnect : idConnect ? idConnect : idEmployeeSenior}/dependents?origin=${filteredStatus}`, {
                    headers: {
                        authorization: token
                    }
                })

                setDependentsData(dependents.data.data)
                
                setLoading(false)
            }catch(error){
                setLoading(false)
                alert('Erro ao buscar dados do colaborador, se o problema persistir entre em contato com o suporte.')
            }          
        }

        getDatas()
    }, [token, filteredStatus])

    function contractDataForm(){

        return(
            <div className='FormRequestContainer overflow-auto xl:h-[70vh] 2xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='companyName'>Nome da Empresa</label>
                    <input type="text" id='companyName' value={companyName} className="bigInput" disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='branchCNPJ'>Filial</label>
                    <input type="text" id='branchCNPJ' value={branchCnpj} className="bigInput" disabled/>
                </div>                                
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='companyName'>Nome da Empresa</label>
                    <input type="text" value={companyName} disabled/>
                </div>
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='branchCNPJ'>Filial</label>
                    <input type="text" value={branchCnpj} disabled/>
                </div>
                <div className='flex flex-col w-fit'>
                    <label htmlFor='idSenior'>Matrícula</label>
                    <input type="text" id='idSenior' value={idSenior} className="smallInput" disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='employeeName'>Nome do Funcionário</label>
                    <input type="text" id='employeeName' value={employeeData?.employeeName} className="bigInput" disabled/>
                </div>
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='employeeName'>Nome do Funcionário</label>
                    <input type="text" value={employeeData?.employeeName} disabled/>
                </div>
                <div>
                    <label htmlFor="admissionDate">Data Admissão</label>
                    <input type="text" id='admissionDate' value={employeeData?.admissionDate} className="smallInput" disabled/>
                </div>
                <div>
                    <label htmlFor="typeBusinessLink">Tipo Vínculo</label>
                    <input id="typeBusinessLink" value={
                        employeeData?.typeBusinessLink == 10 ? 'Empregado ou Intermitente' 
                        : employeeData?.typeBusinessLink == 90 ? 'Estagiario' 
                        : employeeData?.typeBusinessLink == 55 ? 'Menor Aprendiz' 
                        : employeeData?.typeBusinessLink == 49 ? 'Doméstica' 
                        : employeeData?.typeBusinessLink == 60 ? 'Determinado - Lei 9.601/98' 
                        : 'Pro Labore'
                    } className="smallInput" disabled/>
                </div>
                {
                    employeeData?.typeBusinessLink == '90' &&
                    <>
                        <div>
                            <label htmlFor="traineeInsurancePolicy">Apólice de Seguro</label>
                            <input
                                id={'traineeInsurancePolicy'}                        
                                value={employeeData?.traineeInsurancePolicy}  
                                disabled={true}         
                            />
                        </div>
                        <div>
                            <label htmlFor="traineeEndForecast">Término do Estágio</label>
                            <input 
                                disabled={true}
                                id='traineeEndForecast' 
                                value={employeeData?.traineeEndForecast ? verifyValidDate(moment(employeeData?.traineeEndForecast).utc(false).format('DD/MM/YYYY')) : ''}
                                className="smallInput"/>
                        </div>
                        <div>
                            <label htmlFor="traineeEducationalInstitution">Instituição de Ensino</label>                            
                            <input
                                id={'traineeEducationalInstitution'} 
                                disabled={true}
                                value={filteredStatus == 1 ? employeeData?.traineeInstitutionSenior : employeeData?.traineeEducationalInstitution}   
                                className={'bigInput'}                             
                            />
                        </div>
                        <div>
                            <label htmlFor="traineeCodSupervisor">Supervisor</label>
                            <input
                                id={'traineeCodSupervisor'} 
                                disabled={true}                          
                                value={filteredStatus == 1 ? employeeData?.traineeSupervisorSenior : employeeData?.traineeCodSupervisor}
                                className={'bigInput'}
                            />
                        </div>
                        <div>
                            <label htmlFor="traineeOrigin">Natureza do Estágio</label>
                            <input id="traineeOrigin" value={
                                employeeData?.traineeOrigin == '0' ? '' :
                                employeeData?.traineeOrigin == 'O' ? 'Obrigatório' :
                                employeeData?.traineeOrigin == 'N' ? 'Não Obrigatório' :
                                ''
                                
                                } disabled={true}/>
                        </div>
                        <div>
                            <label htmlFor="traineeEducationLevel">Nível do Estágio</label>
                            <input id="traineeEducationLevel" value={
                                employeeData?.traineeEducationLevel == 0 ? '' :
                                employeeData?.traineeEducationLevel == 1 ? 'Fundamental' :
                                employeeData?.traineeEducationLevel == 2 ? 'Médio' :
                                employeeData?.traineeEducationLevel == 3 ? 'Formação' :
                                employeeData?.traineeEducationLevel == 4 ? 'Superior' :
                                employeeData?.traineeEducationLevel == 8 ? 'Especial' :
                                employeeData?.traineeEducationLevel == 9 ? 'Mãe social. (Lei 7644, de 1987)' :
                                ''
                            } disabled={true} className={'mediumInput'}/>
                        </div>
                    </>
                }
                {
                    employeeData?.typeBusinessLink == '55' && filteredStatus == 2 ?
                    <>
                        <div>
                            <label htmlFor="apprenticeInstitutionName">Nome da Instituição do Curso</label>
                            <input
                                id={'apprenticeInstitutionName'}              
                                value={employeeData?.apprenticeInstitutionName ? employeeData?.apprenticeInstitutionName : ''}  
                                disabled={true}
                                className="mediumInput"
                            />
                        </div>
                        <div>
                            <label htmlFor="apprenticeInstitutionCNPJ">CNPJ da Instituição do Curso</label>
                            <input
                                id={'apprenticeInstitutionCNPJ'} 
                                value={employeeData?.apprenticeInstitutionCNPJ ? cnpjMask(employeeData?.apprenticeInstitutionCNPJ) : ''}
                                disabled={true}
                                className="mediumInput"
                            />
                        </div>
                        <div>
                            <label htmlFor="apprenticeDateFinish">Data do Término Previsto do Contrato </label>
                            <input type="date" id='apprenticeDateFinish' value={employeeData?.apprenticeDateFinish?.split('T')[0]} className="smallInput" disabled/>
                        </div>
                    </>
                    : 
                    <></>
                }
                <div>
                    <label htmlFor="typeApuration">Tipo Apuração Ponto</label>
                    <input id="typeApuration" className="smallInput" value={
                        employeeData?.typeApuration == 1 ?
                        'Normal' : employeeData?.typeApuration == 5 ?
                        'Sem Controle de Ponto e Refeitório' : employeeData?.typeApuration == null ? 
                        'Não aplicado' : employeeData?.typeApuration
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="employeeLaborUnion">Funcionário Sindicalizado</label>
                    <input id="employeeLaborUnion" className="smallInput" value={
                        employeeData?.employeeLaborUnion == 'S' || employeeData?.employeeLaborUnion == true ? 'Sim' : 'Não'    
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-5'>
                    <label htmlFor="laborUnion">Sindicato</label>
                    <input type="text" id='laborUnion' className="bigInput" value={
                        employeeData?.employeeLaborUnion == 'S' || true ? employeeData?.laborUnion : 'Não aplicado'    
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-5'>
                    <label htmlFor="employeePosition">Cargo</label>   
                    <input type="text" id='employeePosition' className="bigInput" value={employeeData?.employeePosition}  disabled/> 
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="laborUnion">Sindicato</label>
                    <input type="text" id='laborUnion' value={
                        employeeData?.employeeLaborUnion == 'S' || true ? employeeData?.laborUnion : 'Não aplicado'    
                    } disabled/>
                </div>
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="employeePosition">Cargo</label>   
                    <input type="text" id='employeePosition' value={employeeData?.employeePosition}  disabled/> 
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeePositionCBO">CBO</label>   
                    <input type="text" id='employeePositionCBO' value={employeeData?.employeePositionCBO}  className="bigInput" disabled/> 
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="employeePositionCBO">CBO</label>   
                    <input type="text" id='employeePositionCBO' value={employeeData?.employeePositionCBO}  disabled/> 
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeePositionCBO">Posto de trabalho</label>   
                    <input type="text" id='employeeWorkplace' value={employeeData?.employeeWorkplace}  className="bigInput" disabled/> 
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="employeePositionCBO">Posto de trabalho</label>   
                    <input type="text" id='employeeWorkplace' value={employeeData?.employeeWorkplace} disabled/> 
                </div>
                {
                    filteredStatus == 1 ? (
                        <div>
                            <label htmlFor="scheduleDescription">Escala de Trabalho</label>   
                            <input id="scheduleDescription" className='mediumInput' value={employeeData?.employeeSchedule} disabled/>
                        </div>
                    ) 
                    : 
                    <div>
                        <label htmlFor="employeeSchedule">Escala de Trabalho</label>
                        {
                            employeeData?.employeeScheduleType == '' || employeeData?.employeeScheduleFriday == '' || employeeData?.employeeScheduleMonday == '' || employeeData?.employeeScheduleSaturday == '' ||
                                employeeData?.employeeScheduleSunday == '' || employeeData?.employeeScheduleThursday == '' || employeeData?.employeeScheduleTuesday == '' || employeeData?.employeeScheduleWednesday == '' ?
                                <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput notCompleted" value="Informar" required /> :
                                <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput" value="Consultar" />
                        }
                    </div>
                }
                <div>
                    <label htmlFor="employeeSalary">Salário Base</label>   
                    <input 
                        type="text" id='employeeSalary' 
                        className="smallInput" 
                        value={new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(employeeData?.employeeSalary)} 
                        disabled
                    /> 
                </div>
                {
                    employeeData?.codSelection && employeeData?.codSelection == 5 &&
                    <div>
                        <label htmlFor="codSelection">Gratificação 40%</label>   
                        <input 
                            type="text" id='codSelection' 
                            className="smallInput" 
                            value={new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(employeeData?.valueGratification)} 
                            disabled
                        /> 
                    </div>
                }
                <div>
                    <label htmlFor="typeSalary">Salário Tipo</label>   
                    <input id="typeSalary" value={
                        employeeData?.employeeSalaryType || employeeData?.typeSalary == 1 ? 
                        "Mensalista" : employeeData?.employeeSalaryType || employeeData?.typeSalary == 2 ?
                        "Horista" : employeeData?.employeeSalaryType || employeeData?.typeSalary == 3 ?
                        "Diarista" : employeeData?.employeeSalaryType || employeeData?.typeSalary == 4 ?
                        "Comissionado" : employeeData?.employeeSalaryType || employeeData?.typeSalary == 5 ?
                        "Tarefeiro" : "Terceiro"
                    }  disabled  className='mediumInput'/>
                </div>
                <div>
                    <label htmlFor="employeeLocal">Local/Setor</label>   
                    <input type="text" id="employeeLocal" className='mediumInput' value={employeeData?.employeeLocalDescription} disabled/>
                </div>
                <div>
                    <label htmlFor="employeeCCU">Centro de Custos</label>   
                    <input type="text" id="employeeCCU" className='mediumInput' 
                        value={
                            employeeData?.employeeCcu && filteredStatus == 1 ? employeeData?.employeeCcu + " - " + employeeData?.employeeCcuDescription : employeeData?.employeeCCU} 
                    disabled/>
                </div>
                <div>
                    <label htmlFor="employeeExperience">Periodo de experiência</label>   
                    <input type="text" id="employeeExperience" className='mediumInput' value={
                        filteredStatus == 1 ? 
                            employeeData?.employeeExperience : 
                            employeeData?.employeeExperience == 1 ? '30 + 60' :
                            employeeData?.employeeExperience == 2 ? '45 + 45' :
                            employeeData?.employeeExperience == 3 ? '60 + 30' :
                            employeeData?.employeeExperience == 4 ? '30 + 30' :
                            'Não aplicavel'
                    } disabled/>
                </div>
                {
                    filteredStatus  == 1 ? (
                        <>
                            <div>
                                <label htmlFor="employeeExperienceStart">Primeiro Vencimento</label>   
                                <input type="text" id="employeeExperienceStart" className='mediumInput' value={employeeData?.employeeExperienceStart ? verifyValidDate(employeeData?.employeeExperienceStart) : ''} disabled/>
                            </div>
                            <div>
                                <label htmlFor="employeeExperienceEnd">Segundo Vencimento</label>   
                                <input type="text" id="employeeExperienceEnd" className='mediumInput' value={employeeData?.employeeExperienceEnd ? verifyValidDate(employeeData?.employeeExperienceEnd) : ''} disabled/>
                            </div>
                        </>
                    ) : <></>
                }
                
                <div>
                    <label htmlFor="employeeVT">Recebe VT</label>   
                    <input id="employeeVT" className='smallInput' value={employeeData?.employeeVT ? 'Sim' : employeeData?.employeeVtSchedule ? 'Sim' : 'Não'} disabled/>
                </div>
                {
                    employeeData?.employeeVT || employeeData?.employeeVtSchedule > 0 ?
                    <div>
                        <label htmlFor="employeeVT">Quantidade por dia</label>   
                        <input id="employeeVT" className='smallInput' value={employeeData?.employeeValueVT ? employeeData?.employeeValueVT : employeeData?.employeeVtSchedule ? employeeData?.employeeVtSchedule : ''} disabled/>
                    </div>
                    : <></>
                }
                <div>
                    <label htmlFor="advanceSalary">Adiantamento Salarial</label>   
                    <input id="employeeVT" className='smallInput' value={(employeeData?.advanceSalary == 'S' && employeeData?.advanceSalary) || (employeeData?.advanceSalary != 'N' && employeeData?.advanceSalary) ? 'Sim' : 'Não'} disabled/>
                </div>
                <div>
                    <label htmlFor="advanceSalaryPorcentage">Porcentagem</label>   
                    <input id="employeeVT" className='smallInput' value={employeeData?.advanceSalaryPorcentage ? employeeData?.advanceSalaryPorcentage + "%" : 'N/D'} disabled/>
                </div>
                <div>
                    <label htmlFor="advanceMonthAdmission">Recebe Adto Mês Admissão</label>   
                    <input id="advanceMonthAdmission" className='smallInput' value={(employeeData?.advanceMonthAdmission == 'S' && employeeData?.advanceMonthAdmission) || (employeeData?.advanceMonthAdmission != 'N' && employeeData?.advanceMonthAdmission) ? 'Sim' : 'Não'} disabled/>
                </div>
                <div>
                    <label htmlFor="insalubrity">Insalubridade</label>   
                    <input type="text" id="insalubrity" className='smallInput' value={
                        employeeData?.insalubrityPercentage ? 'Sim' : employeeData?.insalubrity == 0 || !employeeData?.insalubrity ? 'Não' : 'Sim'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="insalubrityPercentage">Porcentagem Insalubridade</label>   
                    <input type="text" id="insalubrityPercentage" className='smallInput' value={
                        employeeData?.insalubrityPercentage ? employeeData?.insalubrityPercentage : ''
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="periculosity">Periculosidade</label>   
                    <input type="text" id="periculosity" className='smallInput' value={ 
                        employeeData?.periculosityPercentage ? 'Sim' : employeeData?.periculosity == 0 || !employeeData?.periculosity ? 'Não' : 'Sim'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="periculosityPercentage">Porcentagem Periculosidade</label>   
                    <input type="text" id="periculosityPercentage" className='smallInput' value={ 
                        employeeData?.periculosityPercentage ? employeeData?.periculosityPercentage : ''
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="paymentPeriod">Período de Pagmento</label>   
                    <input id="paymentPeriod" value={
                        employeeData?.paymentPeriod == 'M' ?
                        'Mensal' : employeeData?.paymentPeriod == 'Q' ?
                        'Quinzenal' : employeeData?.paymentPeriod == 'D' ?
                        'Diário' : 'Semanal'
                    } className='mediumInput' disabled/>
                </div>
                <div>
                    <label htmlFor="paymentMethod">Forma de Pagamento</label>   
                    <input id="paymentMethod" value={
                        employeeData?.paymentMethod == 'R' ?
                        'Relação Bancaria' : employeeData?.paymentMethod == 'C' ?
                        'Cheque' : employeeData?.paymentMethod == 'D' ?
                        'Dinheiro' : 'Ordem Pgto.'
                    } className='mediumInput' disabled/>
                </div>
                <div>
                    <label htmlFor="employeeBank">Banco</label>   
                    <input type="text" id="employeeBank" 
                        value={employeeData?.employeeBankName && filteredStatus == 1 ? employeeData?.employeeBankName : employeeData?.employeeBank || employeeData?.employeeBank > 0 ? employeeData?.employeeBank : '-'}
                        className='smallInput' disabled/>
                </div>
                <div>
                    <label htmlFor="employeeBankAgencies">Agência</label>   
                    <input type="text" id="employeeBankAgencies" 
                        value={!employeeData?.employeBankAgencie || employeeData?.employeBankAgencie == -1 ? '-' : employeeData?.employeBankAgencie} 
                        className='smallInput' 
                        disabled
                    />
                </div>
                {
                    filteredStatus == 2 ?
                    <div>
                        <label htmlFor="employeeBankAgencies">Digito Agência</label>   
                        <input type="text" id="employeeBankAgencies" 
                            value={employeeData?.digitAgency  || '-'} 
                            className='smallInput' 
                            disabled
                        />
                    </div>
                    : <></>
                }
                <div>
                    <label htmlFor="employeeBankAccountType">Tipo de Conta</label>   
                    <input type="text" id="employeeBankAccountType" 
                        value={employeeData?.employeeBankAccountType == 1 ?
                        'Corrente' : employeeData?.employeeBankAccountType == 2 ?
                        'Poupança' : employeeData?.employeeBankAccountType == 3 ?
                        'Salário'  : employeeData?.employeeBankAccountType == 4 ? 'Outros' : '-'
                        } className='smallInput' disabled
                    />
                </div>
                <div>
                    <label htmlFor="employeeBankAccount">Nº Conta</label>   
                    <input 
                        type="text" 
                        id="employeeBankAccount" 
                        value={employeeData?.employeeBankAccount ? employeeData?.employeeBankAccount : '-'} 
                        className='mediumInput' 
                        disabled
                    />
                </div>
                <div>
                    <label htmlFor="employeeBankAccountDigit">Dígito Conta</label>   
                    <input 
                        type="text" 
                        id="employeeBankAccount" 
                        value={employeeData?.employeeBankAccountDigit ? employeeData?.employeeBankAccountDigit : '-'} 
                        className='smallInput' 
                        disabled
                    />
                </div>
                {
                    filteredStatus == 2 &&
                    <div>
                        <label htmlFor="observation">Observação</label>   
                        <input 
                            type="text" 
                            id="employeeBankAccount" 
                            value={employeeData?.observation ? employeeData?.observation : '-'} 
                            className='mediumInput' 
                            disabled
                        />
                    </div>
                }
            </div>
        )
    }

    function employeeDataForm(){
        return(
            <div className='FormRequestContainer overflow-auto xl:h-[70vh] 2xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                <div>
                    <label htmlFor="nickname">Apelido</label>
                    <input id="nickname" className='smallInput' value={employeeData?.nickname ? employeeData?.nickname : '-'} disabled/>
                </div>
                <div>
                    <label htmlFor="employeeSexualGender">Sexo</label>
                    <input id="employeeSexualGender" className='smallInput' value={
                        employeeData?.employeeSexualGender == 'M' ? 
                        'Masculino' : employeeData?.employeeSexualGender == 'F' ? 
                        'Feminino' : 'Não informado'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="civilStatus">Estado Civil</label>
                    <input id="civilStatus" className='smallInput' value={
                        employeeData?.civilStatus == 1 ? 
                        'Solteiro' : employeeData?.civilStatus == 2 ? 
                        'Casado' : employeeData?.civilStatus == 3 ? 
                        'Divorciado' : employeeData?.civilStatus == 4 ? 
                        'Viúvo' : employeeData?.civilStatus == 5 ? 
                        'Concubinado' : employeeData?.civilStatus == 6 ?
                        'Separado' : employeeData?.civilStatus == 7 ?
                        'União Estável' : 'Outros'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="levelOfEducation">Formação</label>
                    <input id="levelOfEducation"  className='smallInput' value={
                        employeeData?.levelOfEducation == 1 ?  "Analfabeto" :
                        employeeData?.levelOfEducation == 2 ? "4ª série incompleta" :
                        employeeData?.levelOfEducation == 3 ? "4ª série completa" :
                        employeeData?.levelOfEducation == 4 ? "5ª a 8ª série incompleta" :
                        employeeData?.levelOfEducation == 5 ? "1º grau completo" :
                        employeeData?.levelOfEducation == 6 ? "2º grau incompleto " :
                        employeeData?.levelOfEducation == 7 ? "2º grau completo" :
                        employeeData?.levelOfEducation == 8 ? "Superior incompleto" :
                        employeeData?.levelOfEducation == 9 ? "Superior completo" :
                        employeeData?.levelOfEducation == 10 ? "Pós-graduação" :
                        employeeData?.levelOfEducation == 11 ? "Mestrado Completo" :
                        employeeData?.levelOfEducation == 12 ? "Doutorado Completo" :
                        employeeData?.levelOfEducation == 13 ? "Ph.D" :
                        employeeData?.levelOfEducation == 14 ? "2º grau tec. incompleto" :
                        employeeData?.levelOfEducation == 15 ? "2º grau tec. completo" :
                        employeeData?.levelOfEducation == 16 ? "Mestrado Incompleto" :
                        employeeData?.levelOfEducation == 17 ? "Doutorado Incompleto" :
                        employeeData?.levelOfEducation == 18 ? "Pós-graduação Incompleto" : 
                        employeeData?.levelOfEducation ? employeeData?.levelOfEducation : 'Nada declarado'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="birthday">Data de Nascimento</label>
                    <input type="text" id='birthday' className='smallInput' value={employeeData?.birthday} disabled/>
                </div>
                <div>
                    <label htmlFor="nationality">Nacionalidade</label>
                    <input id="nationality"  value={
                        !isNaN(parseInt(employeeData?.nationality)) ? employeeData?.nationalityDescription : employeeData?.nationality
                    } disabled/>
                </div>
                {
                    employeeData?.nationality && employeeData?.nationality != "Brasileiro" && employeeData?.nationality != 10 &&
                    <>
                        <div>
                            <label htmlFor="foreignArrivalDate">Data Chegada</label>
                            <input 
                                id='foreignArrivalDate' 
                                value={employeeData?.foreignArrivalDate ? verifyValidDate(moment(employeeData?.foreignArrivalDate).utc(false).format('DD/MM/YYYY')) : ''}
                                className="smallInput" disabled/>
                        </div>
                        <div>
                            <label htmlFor="foreignResidenceTime">Tempo de Residência</label>
                            <input id="foreignResidenceTime" value={
                                employeeData?.foreignResidenceTime == 0 ? '' :
                                employeeData?.foreignResidenceTime == 1 ? 'Prazo indeterminado' :
                                employeeData?.foreignResidenceTime == 2 ? 'Prazo determinado' :
                                ''
                            } disabled={true}/>
                        </div>
                        {
                            employeeData?.foreignResidenceTime == 2 &&
                            <div>
                                <label htmlFor="foreignFinishResidence">Data de Término da Residência</label>
                                <input 
                                    id='foreignFinishResidence' 
                                    value={employeeData?.foreignFinishResidence ? verifyValidDate(moment(employeeData?.foreignFinishResidence).utc(false).format('DD/MM/YYYY')) : ''} 
                                    className="smallInput" disabled={true}/>
                            </div>
                        }
                        <div>
                            <label htmlFor="foreignCondition">Condição Estrangeiro</label>
                            <input id="foreignCondition" value={
                                employeeData?.foreignCondition == 1 ? 'Visto permanente' :
                                employeeData?.foreignCondition == 2 ? 'Visto temporário' :
                                employeeData?.foreignCondition == 3 ? 'Asilado' :
                                employeeData?.foreignCondition == 4 ? 'Refugiado' :
                                employeeData?.foreignCondition == 5 ? 'Solicitante de Refúgio' :
                                employeeData?.foreignCondition == 6 ? 'Residente fora do Brasil' :
                                employeeData?.foreignCondition == 7 ? 'Deficiente físico e com mais de 51 anos' :
                                employeeData?.foreignCondition == 8 ? 'Com residência provisória e anistiado, em situação irregular' :
                                employeeData?.foreignCondition == 9 ? 'Permanência no Brasil em razão de reunião familiar' :
                                employeeData?.foreignCondition == 10 ? 'Beneficiado pelo acordo entre países do Mercosul' :
                                employeeData?.foreignCondition == 11 ? 'Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício ...' :
                                employeeData?.foreignCondition == 12 ? 'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil ...' :
                                employeeData?.foreignCondition == 13 ? 'Outra condição' :
                                ''
                            } disabled={true} className={'bigInput'}/>
                        </div>
                        <div>
                            <label htmlFor="foreignRegistry">Reg.Estrangeiro</label>
                            <input                  
                                value={employeeData?.foreignRegistry ? employeeData?.foreignRegistry : ''}
                                disabled={true}                       
                            />
                        </div>
                        <div>
                            <label htmlFor="foreignDateExpRegistry">Data Expedição Reg.Estrangeiro</label>
                            <input 
                                id='foreignDateExpRegistry' 
                                value={employeeData?.foreignDateExpRegistry ? verifyValidDate(moment(employeeData?.foreignDateExpRegistry).utc(false).format('DD/MM/YYYY')) : ''}
                                disabled={true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label htmlFor="foreignVisaValidity">Validade do Visto</label>
                            <input 
                                id='foreignVisaValidity' 
                                value={employeeData?.foreignVisaValidity ? verifyValidDate(moment(employeeData?.foreignVisaValidity).format('DD/MM/YYYY')) : ''} 
                                disabled={true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label htmlFor="foreignDateValidityCTPS">Data Validade CTPS</label>
                            <input 
                                id='foreignDateValidityCTPS' 
                                value={employeeData?.foreignDateValidityCTPS ? verifyValidDate(moment(employeeData?.foreignDateValidityCTPS).format('DD/MM/YYYY')) : ''} 
                                disabled={true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label htmlFor="foreignCasWithBrazilian">Casado com Brasileiro</label>
                            <input id="foreignCasWithBrazilian" value={
                                employeeData?.foreignCasWithBrazilian == 'S' ? 'Sim' :
                                employeeData?.foreignCasWithBrazilian == 'N' ? 'Não' :
                                ''
                            } className={'smallInput'} disabled/>
                        </div>
                        <div>
                            <label htmlFor="foreignBrazilianChildren">Filhos Brasileiros</label>
                            <input id="foreignBrazilianChildren" value={
                                employeeData?.foreignBrazilianChildren == 'S' ? 'Sim' :
                                employeeData?.foreignBrazilianChildren == 'N' ? 'Não' :
                                ''
                                } className={'smallInput'} disabled/>
                        </div>
                    </>
                }
                <div>
                    <label htmlFor="employeeUF">UF Nascimento</label>
                    <input type="text" id='employeeUF' maxlength="2" className='smallInput' value={
                        employeeData?.employeeUf
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="workCard">Carteira de Trabalho</label>
                    <input type="text" id='workCard'  className='mediumInput' value={
                        employeeData?.workCard
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="workCardSerie">CTPS Serie</label>
                    <input type="text" id='workCardSerie'  className='smallInput' value={
                        employeeData?.workCardSerie
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="workCardEmission">CTPS Emissão</label>
                    <input type="text" id='workCardEmission' className='smallInput' value={
                        verifyValidDate(employeeData?.workCardEmission)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="workCardUF">CTPS UF</label>
                    <input id="workCardUF" className='smallInput' value={
                        employeeData?.workCardUf
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cpf">CPF</label>
                    <input type="text" id='cpf' maxlength="11" className='smallInput' value={
                        filteredStatus == 1 ? employeeData?.cpf : cpfMask(employeeData?.cpf)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="pis">PIS</label>
                    <input type="text" id='pis' className='mediumInput' value={
                        pisMask(employeeData?.pis)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="pisEmission">Emissão PIS</label>
                    <input type="text" id='pisEmission' className='smallInput' value={
                        verifyValidDate(employeeData?.emissionPis)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="RG">RG</label>
                    <input type="text" id='RG' className='mediumInput' value={
                        employeeData?.rg
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="sendingAgencyRG">RG Orgão Expedidor</label>
                    <input type="text" id='sendingAgencyRG' className='mediumInput' value={
                        employeeData?.sendingAgencyRG
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="emissionRG">RG Emissão</label>
                    <input type="text" id='emissionRG' className='smallInput' value={
                        verifyValidDate(employeeData?.emissionRG)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="RGuf">RG UF</label>
                    <input id="RGuf" className='smallInput' value={
                        employeeData?.rgUf ? employeeData?.rgUf : ' - ' 
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="voterRegistration">Titulo de Eleitor</label>
                    <input type="text" id='voterRegistration' className='smallInput' value={
                        employeeData?.voterRegistration ? employeeData?.voterRegistration : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="voterRegistrationZone">Titulo de Eleitor Zona</label>
                    <input type="text" id='voterRegistrationZone' className='smallInput' value={
                        employeeData?.voterRegistrationZone ? employeeData?.voterRegistrationZone : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="voterRegistrationSection">Titulo de Eleitor Seção</label>
                    <input type="text" id='voterRegistrationSection' className='smallInput' value={
                        employeeData?.voterRegistrationSection ?  employeeData?.voterRegistrationSection : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnh"> CNH </label>
                    <input type="text" id='cnh' className='mediumInput' value={
                        employeeData?.cnh ? employeeData?.cnh : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhCategory"> Categoria CNH  </label>
                    <input type="text" id='cnhCategory' className='smallInput' value={
                        employeeData?.cnhCategory ?  employeeData?.cnhCategory : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhValidity"> Validade CNH </label>
                    <input type="text" id='cnhValidity' className='smallInput' value={
                        verifyValidDate(employeeData?.cnhValidity)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhIssuer"> Orgão Emissor CNH  </label>
                    <input type="text" id='cnhIssuer' className='smallInput' value={
                        employeeData?.cnhIssues ? employeeData?.cnhIssues : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhUf"> UF CNH </label>
                    <input id="cnhUf" className='smallInput' value={
                        employeeData?.cnhUf ? employeeData?.cnhUf : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhFirstDate"> Data 1ª CNH </label>
                    <input type="text" id='cnhFirstDate' className='smallInput' value={
                        verifyValidDate(employeeData?.cnhFirstDate)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="cnhDispatchDate"> Data Expedição CNH </label>
                    <input type="text" id='cnhDispatchDate' className='smallInput' value={
                        verifyValidDate(employeeData?.cnhDispatchDate)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="militaryServiceCard"> Carteira Reservista </label>
                    <input type="text" id='militaryServiceCard' className='mediumInput' value={
                        employeeData?.militaryServiceCard ? employeeData?.militaryServiceCard : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="militaryServiceCardUf"> UF Carteira Reservista </label>
                    <input type="text" id='militaryServiceCardUf' className='smallInput' value={
                        employeeData?.militaryServiceCardUf ? employeeData?.militaryServiceCardUf : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="deficiency"> Possui Deficiência</label>
                    <input type="text" id='deficiency' value={
                        employeeData?.deficiency == 'N' || !employeeData?.deficiency ? 'Não' : 'Sim'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="deficiencyType"> Tipo de Deficiencia </label>
                    <input type="text" id='deficiencyType' className='smallInput' value={
                        employeeData?.deficiencyType == 0 || !employeeData?.deficiencyType ? 'Não Aplicável' : 
                        employeeData?.deficiencyType == 1 ? 'Deficiente Visual' :
                        employeeData?.deficiencyType == 2 ? 'Deficiente Fisica' :
                        employeeData?.deficiencyType == 3 ? 'Deficiente Auditiva' :
                        employeeData?.deficiencyType == 4 ? 'Deficiente Intelectual' :
                        employeeData?.deficiencyType == 5 ? 'Deficiente Mental' : 
                        employeeData?.deficiencyType == 6 ? 'Deficiente Multipla' : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="deficiencyRehabilitatedInss"> Empregado Reabilitado do INSS </label>
                    <input type="text" id='deficiencyRehabilitatedInss' value="Não Aplicável" className='smallInput' disabled/>
                </div>
                <div>
                    <label htmlFor="deficiencyPcdQuote"> Compôe cota de PcD </label>
                    <input type="text" id='deficiencyPcdQuote' className='smallInput' value={
                        employeeData?.deficiencyPcdQuote == 'N' || !employeeData?.deficiencyPcdQuote ? 'Não' : 'Sim'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="employeeRace"> Raça </label>
                    <input type="text" id='employeeRace' value={
                        employeeData?.employeeRace == 0 || !employeeData?.employeeRace ? 'Não informado' :
                        employeeData?.employeeRace == 1 ? 'Branca' :
                        employeeData?.employeeRace == 2 ? 'Preta' :
                        employeeData?.employeeRace == 3 ? 'Amarela' :
                        employeeData?.employeeRace == 4 ? 'Parda' :
                        employeeData?.employeeRace == 5 ? 'Indígena' :
                        employeeData?.employeeRace == 6 ? 'Mameluco' :
                        'Cafuzo'
                    } className='smallInput' disabled/>
                </div>
                <div>
                    <label htmlFor="addressCountry"> País </label>
                    <input type="text" id='addressCountry' className='smallInput' value={
                        filteredStatus == 1 ? employeeData?.addressCountryName : employeeData?.addressCountry
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="addressState"> Estado </label>
                    <input type="text" id='addressState'className='smallInput' value={
                        employeeData?.addressState
                    } disabled />
                </div>
                <div>
                    <label htmlFor="addressCities"> Cidade </label>
                    <input type="text" id='addressCities' className='mediumInput' value={
                        filteredStatus == 1 ? employeeData?.addressCityName : employeeData?.addressCity
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="addressDistrict"> Bairro </label>
                    <input type="text" id='addressDistrict' className='mediumInput' value={
                        filteredStatus == 1 ? employeeData?.addressDistrictName : employeeData?.addressDistrict
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="addressCep"> CEP </label>
                    <input type="text" id='addressCep' className='smallInput' value={
                        maskCep(employeeData?.addressCep)
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="addressType"> Tipo de Logradouro </label>
                    <input type="text" id='addressType' className='smallInput' value={
                        employeeData?.addressType == 'AV' ? 'Avenida' :
                        employeeData?.addressType == 'VLA' ? 'Viela' :
                        employeeData?.addressType == 'TV' ? 'Travessa' :
                        employeeData?.addressType == 'ROD' ? 'Rodovia' :
                        employeeData?.addressType == 'EST' ? 'Estrada' : 'Rua'
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="addressRoad"> Logradouro </label>
                    <input type="text" id='addressRoad' className='bigInput' value={
                        employeeData?.addressRoad
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="addressNumber"> Número </label>
                    <input type="number" id='addressNumber' className='bigInput'value={
                        employeeData?.addressNumber
                    } disabled/>
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="addressRoad"> Logradouro </label>
                    <input type="text" id='addressRoad' value={
                        employeeData?.addressRoad
                    } disabled/>
                </div>
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor="addressNumber"> Número </label>
                    <input type="number" id='addressNumber' value={
                        employeeData?.addressNumber
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="addressComplement"> Complemento </label>
                    <input type="text" id='addressComplement' className='mediumInput' value={
                        employeeData?.addressComplement ? employeeData?.addressComplement : '-'
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="phoneDdd"> DDD do Telefone </label>
                    <input type="text" id='phoneDdd' className='smallInput' value={employeeData?.phoneDDD} disabled/>
                </div>
                <div>
                    <label htmlFor="phoneNumber"> Número do Telefone </label>
                    <input type="text" id='phoneNumber' className='smallInput' value={
                        employeeData?.phoneNumber
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeeEmail"> E-mail Particular </label>
                    <input type="text" id='employeeEmail' className='bigInput' value={
                        employeeData?.employeeEmail
                    } disabled/>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' className='bigInput' value={
                        employeeData?.employeeEmailCorporate
                    } disabled/>
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmail"> E-mail Particular </label>
                    <input type="text" id='employeeEmail' value={
                        employeeData?.employeeEmail
                    } disabled/>
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' value={
                        employeeData?.employeeEmailCorporate
                    } disabled/>
                </div>
                <div>
                    <label htmlFor="dateEmploymentExamination"> Data do exame Admissional </label>
                    <input type="text" id='dateEmploymentExamination' className='mediumInput' value={
                        verifyValidDate(employeeData?.dateEmploymentExamination)
                    } disabled/>
                </div>
            </div>
        )
    }

    function dependentsDataForm(){
        return(
            <div className='FormRequestContainer overflow-auto xl:h-[70vh] 2xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    filteredStatus == 2 ? (
                        <>
                            <div className='hidden lg:flex lg:flex-col lg:h-7 xl:h-fit'>
                                <label htmlFor="motherName"> Nome da Mãe </label>
                                <input type="text" id='motherName' value={employeeData?.motherName} className='bigInput' disabled={true} />
                            </div>
                            <div className='hidden lg:flex lg:flex-col lg:h-7 xl:h-fit'>
                                <label htmlFor="motherName"> Nome do Pai </label>
                                <input type="text" id='motherName' value={employeeData?.fatherName ? employeeData?.fatherName : 'Não informado'} className='bigInput' disabled={true} />
                            </div>
                            {/* Mobile */}
                            <div className='flex lg:hidden flex-col w-full' id='onlyMobile'>
                                <label htmlFor="motherName"> Nome da Mãe </label>
                                <input type="text" id='motherName' value={employeeData?.motherName} disabled={true} />
                            </div>
                            <div className='flex lg:hidden flex-col w-full' id='onlyMobile'>
                                <label htmlFor="motherName"> Nome do Pai </label>
                                <input type="text" id='motherName' value={employeeData?.fatherName ? employeeData?.fatherName : 'Não informado'} disabled={true} />
                            </div>
                        </>
                    ): <></>
                }
                <div className='flex flex-col items-center justify-center'>
                    {
                        dependentsData?.map((dependent, index) => {
                            return (
                                <div className='border-b border-b-[#005f9e36] flex items-center justify-center gap-2'>
                                    <div className='dependentsContainer'>
                                        <div className='flex flex-col items-center justify-center'>
                                            <label htmlFor={`dependentName${index}`}>Nome</label>
                                            <input type="text" id={`dependentName${index}`} classname='soSmallInputLittle' value={dependent.dependentName} disabled />
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <label htmlFor={`dependentType${index}`}>Tipo</label>
                                            <input type="text" id={`dependentType${index}`} classname='soSmallInputLittle' value={
                                                dependent.dependentType == 1 ? 'Filho' :
                                                dependent.dependentType == 2 ? 'Cônjuge' :
                                                'Pai/Mãe'
                                            } disabled />
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <label htmlFor={`dependentCPF${index}`}>CPF</label>
                                            <input type="text" id={`dependentCPF${index}`} classname='soSmallInputLittle' value={dependent.dependentCpf} disabled />
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <label htmlFor={`dependentSex${index}`}>Sexo</label>
                                            <input type="text" id={`dependentSex${index}`} classname='soSmallInputLittle' value={dependent.sexualGender == 'F'? 'Feminino' : 'Masculino'} disabled />
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <label htmlFor={`dependentBirthday${index}`}>Data Nascimento</label>
                                            <input type="text" id={`dependentBirthday${index}`} classname='soSmallInputLittle' value={filteredStatus == 1 ? dependent.dependentBirthday : verifyValidDate(dependent.dependentBirthday) ? verifyValidDate(moment(dependent?.dependentBirthday).utc(false).format('DD/MM/YYYY')) : ''} disabled />
                                        </div>
                                        {
                                            dependent.dependentType == 1 ? (
                                                <div className='flex flex-col items-center justify-center'>
                                                    <label htmlFor={`courtPension${index}`}>Pensão Judicial</label>
                                                    <input type="text" id={`courtPension${index}`} classname='soSmallInputLittle' value={
                                                        !dependent.courtPension || dependent.courtPension == 'N' || dependent.courtPension == 'n' ? 'Não' : 'SIM' 
                                                    } disabled />
                                                </div>
                                            ) : <></>
                                        }
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    async function generatePdf(e){
        e.preventDefault()
        
        try {            

            setLoading(true)
            
            const htmlData = document.getElementById('exportEmployee').innerHTML
            const pdfWindow = window.open('Folpag Connect', 'Folpag Connect', 'width=1920, height= 1080')
            pdfWindow.document.write('<html lang="pt-BR">')
            pdfWindow.document.write(`<head>`)
            pdfWindow.document.write(`
                <meta charset="utf-8" />
                <title>
                    Colaborador: ${employeeData?.employeeName} | Admissão: ${filteredStatus == 1 ? employeeData?.admissionDate : moment(employeeData?.admissionDate).utc(false).format('DD/MM/YYYY')}
                </title>
                <meta name="description" content="Relatório" />
            `)
            pdfWindow.document.write(`</head>`)
            pdfWindow.document.write(`<style>`)
            pdfWindow.document.write(reportStyle)
            pdfWindow.document.write(`</style>`)
            pdfWindow.document.write('<body>')
            pdfWindow.document.write(htmlData)
            pdfWindow.document.write('</body>')
            pdfWindow.document.write('</html>')
            pdfWindow.document.close()
            pdfWindow.print()
            pdfWindow.close()

            setLoading(false)

        } catch (error) {
            setLoading(false)
            return alert('Erro ao realizar geração de PDF')
        }

    }

    function verifyValidDate(field){
        return field != "31/12/1900" ? field : ''
    }

    function schedulePDFForm(){
        return (
            <div className='modalFields h-fit gap-1'>
                <div className='modalFields h-fit gap-1'>
                    <div>
                        <label htmlFor="employeeScheduleType">Tipo de escala</label>
                        <input id="employeeScheduleType" type='text' value={
                            employeeData?.employeeScheduleType == 1 ? '5x1'
                            : employeeData?.employeeScheduleType == 2 ? '5x2'
                            : employeeData?.employeeScheduleType == 3 ? '6x1'
                            : employeeData?.employeeScheduleType == 4 ? '12x36'
                            : employeeData?.employeeScheduleType == 5 ? '3x1'
                            : ''
                            }>
                        </input>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleMonday">Horário de Segunda</label>
                        <input type="text" id='employeeScheduleMonday' value={employeeData?.employeeScheduleMonday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleTuesday">Horário de Terça</label>
                        <input type="text" id='employeeScheduleTuesday' value={employeeData?.employeeScheduleTuesday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleWednesday">Horário de Quarta</label>
                        <input type="text" id='employeeScheduleWednesday' value={employeeData?.employeeScheduleWednesday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleThursday">Horário de Quinta</label>
                        <input type="text" id='employeeScheduleThursday' value={employeeData?.employeeScheduleThursday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleFriday">Horário de Sexta</label>
                        <input type="text" id='employeeScheduleFriday' value={employeeData?.employeeScheduleFriday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleSaturday">Horário de Sabádo</label>
                        <input type="text" id='employeeScheduleSaturday' value={employeeData?.employeeScheduleSaturday}/>
                    </div>
                    <div>
                        <label htmlFor="employeeScheduleSunday">Horário de Domingo</label>
                        <input type="text" id='employeeScheduleSunday' value={employeeData?.employeeScheduleSunday}/>
                    </div>                                                        
                </div>
            </div>
        )
    }

    async function loadingChangeTab(){
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 100))
        setLoading(false)
    }

    return(
        <>
            <Modal>
                <div className={`${!show ? 'hiddenModal' : 'w-[95%] lg:w-[40rem]'} flex flex-col items-center justify-center`}>
                    <div className='modalParamsDescription bg-slate-200 w-full flex items-center py-4 px-2 font-bold'>
                        <FiChevronRight />
                        <p>Informe abaixo a escala do colaborador.</p>
                    </div>
                    <div className='modalFields h-fit gap-1'>
                        <div>
                            <label htmlFor="employeeScheduleType">Tipo de escala</label>
                            <input id="employeeScheduleType" type='text' value={
                                employeeData?.employeeScheduleType == 1 ? '5x1'
                                : employeeData?.employeeScheduleType == 2 ? '5x2'
                                : employeeData?.employeeScheduleType == 3 ? '6x1'
                                : employeeData?.employeeScheduleType == 4 ? '12x36'
                                : employeeData?.employeeScheduleType == 5 ? '3x1'
                                : ''
                                } disabled>
                            </input>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleMonday">Horário de Segunda</label>
                            <input type="text" id='employeeScheduleMonday' value={employeeData?.employeeScheduleMonday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleTuesday">Horário de Terça</label>
                            <input type="text" id='employeeScheduleTuesday' value={employeeData?.employeeScheduleTuesday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleWednesday">Horário de Quarta</label>
                            <input type="text" id='employeeScheduleWednesday' value={employeeData?.employeeScheduleWednesday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleThursday">Horário de Quinta</label>
                            <input type="text" id='employeeScheduleThursday' value={employeeData?.employeeScheduleThursday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleFriday">Horário de Sexta</label>
                            <input type="text" id='employeeScheduleFriday' value={employeeData?.employeeScheduleFriday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleSaturday">Horário de Sabádo</label>
                            <input type="text" id='employeeScheduleSaturday' value={employeeData?.employeeScheduleSaturday} disabled/>
                        </div>
                        <div>
                            <label htmlFor="employeeScheduleSunday">Horário de Domingo</label>
                            <input type="text" id='employeeScheduleSunday' value={employeeData?.employeeScheduleSunday} disabled/>
                        </div>                                                        
                    </div>
                </div>
            </Modal>
            <div>
                <div className='flex flex-row pt-12 md:ml-20 justify-start w-full h-fit'>
                    <button 
                        onClick={() => ![1,2]?.includes(user?.type) && !idEmployeeSenior ? {} : setFilteredStatus(1)}
                        className={`${filteredStatus == 1 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                        font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                        shadow-gray-400 text-primaryDefaultLight h-7 md:h-6 w-full md:w-36 flex flex-col 
                        items-center duration-300 justify-center ${filteredStatus == 1 ? 'selectedFilterEmployee' : ''}
                        ${![1,2]?.includes(user?.type) && !idEmployeeSenior ? 'cursor-not-allowed' : ''} 
                        `}
                    >   
                        Dados do Senior
                    </button>
                    <button 
                        onClick={() => setFilteredStatus(2)}
                        className={`${filteredStatus == 2 ? 'bg-primaryDefaultLight text-secondaryDefaultLight' : 'bg-[#ececec]'} 
                        font-extrabold hover:bg-gray-300 hover:text-primaryDefaultLight shadow-md 
                        shadow-gray-400 text-primaryDefaultLight h-7 md:h-6 w-full md:w-36 flex flex-col items-center duration-300 
                        justify-center ${filteredStatus == 2 ? 'selectedFilterEmployee' : ''}`}
                    >
                        Dados enviados
                    </button>
                </div>
                <div className='animate-[wiggleLeft_1s_ease-in-out] flex flex-row pt-2 justify-center w-full h-fit mb-20' id='oidhja9s8djada'>
                    <form className={`containerFormsRequestAdmission w-[90%] lg:pb-3 lg:h-full h-[90vh]`}>
                        <div className='hidden lg:flex headerFormRequestAdmission headerFormRequestAdmission'>
                            <div className={selectedForm === 1 ? 'hasHoverRequest selected' :'hasHoverRequest'} onClick={()=> {loadingChangeTab(); setSelectedForm(1)}}><p>Dados do Contrato de Trabalho</p></div> 
                            <div className={selectedForm === 2 ? 'hasHoverRequest selected' :'hasHoverRequest'} onClick={()=> {loadingChangeTab(); setSelectedForm(2)}}><p>Dados do Empregado</p></div> 
                            <div className={selectedForm === 3 ? 'hasHoverRequest selected' :'hasHoverRequest'} onClick={()=> {loadingChangeTab(); setSelectedForm(3)}}><p>Dados dos Dependentes</p></div> 
                            {
                                idEmployeeFolpagConnect == undefined || idEmployeeFolpagConnect == null ? 
                                <div>
                                    <button onClick={() => navigate('/employeeConsult')}> <p> Voltar </p> </button>
                                    <button onClick={generatePdf}> <p> Gerar PDF </p> </button>
                                </div>
                                :
                                <div>
                                    <button onClick={() => navigate('/employeeConsult')}> <p> Voltar </p> </button>
                                    <button onClick={generatePdf}> <p> Gerar PDF </p> </button>
                                </div>
                            }
                        </div>
                        <div className='flex lg:hidden gap-2 justify-around h-10 text-center bg-[#ececec] text-primaryDefaultLight relative border-l border-l-[#ececec] border-r border-r-[#ececec] border-b border-b-[#005F9E]'>
                            <div className={selectedForm === 1 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => setSelectedForm(1)}>
                                <p className='text-xs'>{selectedForm === 1 ? 'Contrato de Trabalho' : 'Contrato...'}</p>
                            </div>
                            <div className={selectedForm === 2 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => setSelectedForm(2)}>
                                <p className='text-xs'>{selectedForm === 2 ? 'Empregado' : 'Empre...'}</p>
                            </div>
                            <div className={selectedForm === 3 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => setSelectedForm(3)}>
                                <p className='text-xs'>{selectedForm === 3 ? 'Dependentes' : 'Depe...'}</p>
                            </div>
                        </div>
                        <div className="FormRequestContainer consultFormContainer">
                            {
                                loading ? 
                                (
                                    <div className='loadingFormRequest'>
                                        <Loader />
                                    </div>
                                ) : selectedForm === 1 ? contractDataForm() 
                                : selectedForm === 2 ? employeeDataForm() 
                                : dependentsDataForm()
                            }
                            {
                                insideLoading ? 
                                (
                                    <div className='insideLoadingForm'>
                                        <Loader />
                                    </div>
                                ) : <></>
                            }
                        </div>
                        <div id="exportEmployee">
                            <h1>Folpag Connect</h1>
                            <h2>Dados de Contrato</h2>
                            <div>
                                {contractDataForm()}
                            </div>
                            <h2>Dados do Empregado</h2>
                            <div>
                                {employeeDataForm() }
                            </div>                            
                            <h2>Dados do Dependente</h2>
                            <div>
                                {dependentsDataForm()}
                            </div>
                            {
                                filteredStatus == 2 &&
                                <>
                                    <h2 className='hidden'>Dados da escala</h2>
                                    {schedulePDFForm()}
                                </>
                            }
                        </div>
                    </form>
                    <div className='flex lg:hidden absolute py-1 z-40 bottom-8 bg-white h-10 w-full flex-row items-center justify-around'>
                        <button 
                            onClick={() => navigate('/employeeConsult')}
                            className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                        > 
                            Voltar
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}