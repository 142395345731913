import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { useTermsOfUse } from '../hooks/useTermsOfUse';

import { Route, HashRouter, Routes } from 'react-router-dom';
import LoginRoute from './login.routes';
import AppRoute from './app.routes';
import UseTermsRoute from './useTerms.routes';
import InstructionsRoute from './useInstructions.routes';
import Logon from '../pages/logon/logon';
import { CompanyBranchProvider } from '../hooks/useCompanyBranch';
import { useAcceptInstructions } from '../hooks/useAcceptInstructions';
import {PreLoader} from '../components/preLoader/preLoader'

export default function GeneralRoutes() {
    const { logged, loadingAuth } = useAuth()
    const { accepted } = useTermsOfUse()
    const { acceptedInstruction } = useAcceptInstructions()

    return (
        <CompanyBranchProvider>
            {
                loadingAuth ?
                <PreLoader/>
                : !logged ? 
                <HashRouter>
                    <Routes>
                        <Route path="/*" element={<Logon />} />
                    </Routes>
                </HashRouter> 
                : logged && !accepted ? 
                <UseTermsRoute /> 
                : logged && !acceptedInstruction ?
                <InstructionsRoute />
                : logged && accepted ? 
                <AppRoute /> 
                : 
                <LoginRoute />
            }
        </CompanyBranchProvider>
    )
}
